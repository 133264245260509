.icon-staractive_fill{
    color: #15B097;
  }
  .icon-heartactive_fill{
    color: red;
  }
  .icon-size24{
    line-height: 24px;
    font-size: 24px !important;
  }
  .icon-size32{
    line-height: 32px;
    font-size: 32px !important;
  }
  .icon-size20{
    line-height: 20px;
    font-size: 20px !important;
  }
  .icon-size15{
    line-height: 15px;
    font-size: 15px !important;
  }
.preview {
  height: 100%;
  position: relative;
}

.chat_box_counter {
  height: calc(100% - 32px);
}

.saveTip {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  font-size: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-color-2);
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  z-index: 1;
}

.pages {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 160px);

  .login_box {
    border-radius: 10px;
    display: flex;
    align-items: center;
    background: var(--theme-bg2);
    color: var(--theme-color-main);
    transition: color 0.6s ease-in-out, background-color 0.6s ease-in-out;
    .form {
      max-width: 600px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 24px;
      color: var(--theme-color-main) !important;

      label {
        color: var(--theme-color-main) !important;
      }

      .form_item {
        width: 100%;
        :global(.ant-form-item-row) {
          width: 100% !important;
        }
        :global(.ant-form-item-control-input-content) {
          display: flex !important;
          justify-content: flex-end !important;
        }
      }
    }

    .input {
      width: 250px;
    }

    :global(
        :where(.css-dev-only-do-not-override-gzal6t).ant-form-item
          .ant-form-item-explain-connected
      ) {
      width: auto;
    }

    .login_btn {
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
    }
  }
}

.page {
  height: 100%;
  // min-width: 1200px;

  .category_index {
    height: 64px;
    border-bottom: 1px solid var(--theme-border-1);
    display: flex;
    padding-left: 24px;
    background: var(--theme-bg);
    overflow-x: scroll;

    .category_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      cursor: pointer;
      color: var(--theme-color-2);
      position: relative;
      font-weight: 500;
      z-index: 5;
      white-space: nowrap;

      &:hover {
        color: var(--theme-color-main);
      }

      .category_name {
        &.category_name_active {
          color: var(--theme-color-main);
        }
      }

      .category_item_name_box {
        display: flex;
        width: 100%;
        align-items: center;
        word-wrap: no-wrap;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
      }

      .category_img {
        margin-right: 4px;
        overflow: hidden;
        position: relative;
        left: -10000px;
        filter: drop-shadow(var(--theme-color-2) 10000px 0);

        &.category_img_active {
          filter: drop-shadow(var(--theme-color-main) 10000px 0);
        }
      }

      .category_item_bottom {
        height: 2px;
        background-color: var(--theme-bg);
        width: 0;
        bottom: 0;
        position: absolute;
        left: 0;
        transition: all 0.2s ease-in-out;

        &.category_item_bottom_active {
          width: 100%;
          background: var(--theme-color-10);
        }
      }
    }
  }

  .category {
    display: flex;
    margin-left: 24px;

    .sider_menu_item {
      margin-top: 24px;
      height: 38px;
      padding: 8px 16px 8px 16px;
      gap: 10px;
      border-radius: 22px;
      display: flex;
      align-items: center;
      background: var(--theme-bg);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-right: 16px;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      .sider_menu_item_icon {
        width: 30px;
        height: 30px;
        margin-right: 12px;
      }
      &.sider_menu_item_active {
        transition: all 0.25s ease-in-out;
        background: var(--theme-bg2);
        color: var(--theme-color-main);
        font-weight: 600;
        .label {
          color: var(--theme-color-main);
          font-weight: 600;
        }
      }
      &:hover {
        background: var(--theme-bg2);
        color: var(--theme-color-main);
        font-weight: 600;
        .label {
          color: var(--theme-color-main);
          font-weight: 600;
        }
      }
    }
  }

  .page_header {
    position: sticky;
    top: 0px;
    background-color: var(--theme-bg);
    z-index: 4;
  }

  .category_two {
    margin-left: 80px;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 24px;
    height: 32px;

    .category_two_item {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      border-radius: 12px;
      margin-right: 20px;
      color: var(--theme-color-2);

      &:hover {
        background-color: var(--theme-bg2);
        color: var(--theme-color-main);
        font-weight: 500;
      }

      &.category_two_item_active {
        background-color: var(--theme-bg2);
        color: var(--theme-color-main);
        font-weight: 500;
      }
    }
  }

  .page_recommand {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 24px;
    margin-right: 52px;
    margin-top: 20px;

    .page_recommand_item {
      margin-right: 28px;
      margin-bottom: 28px;
      width: calc(25% - 28px);

      &:nth-child(4n) {
        // margin-right: 0px;
      }
    }
    .page_recommand_item_card{
      margin-right: 32px;
      margin-bottom: 24px;
      width: calc(20% - 32px);
      max-width: 240px;
    }
  }

  .page_list {
  }

  .skeleton {
    .skeleton_info {
      height: 38px;
      margin-top: 12px;
      display: flex;
      align-items: center;

      .skeleton_info_name {
        margin-left: 8px;
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 76px;
    margin-top: 2px;
    padding-right: 44px;

    :global(.ant-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;
      :global(.ant-pagination-item) {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        background-color: var(--theme-bg2);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :global(a) {
          color: var(--theme-color-main);
        }
      }

      :global(.ant-pagination-item-active) {
        background-color: var(--theme-bg6);
        :global(a) {
          color: var(--theme-color-6);
        }
      }

      :global(.ant-pagination-item-ellipsis) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link-icon) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-disabled) {
        :global(.ant-pagination-item-link) {
          color: var(--theme-color-4);
        }
      }
    }
  }
}



.page {
  height: 100%;
  display: flex;
  position: relative;

  .chat_box {
    height: calc(100vh - 65px);
    // background: var(--theme-bg9);
  }

  .page_menu {
    display: none;
    width: 330px;
    height: 100%;
    overflow-y: auto;
    border-right: 1px solid var(--theme-border-1);

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    & {
      -ms-overflow-style: none;
    }

    & {
      overflow: -moz-scrollbars-none;
    }

    .page_menu_dropdown {
      border-radius: 12px;
      width: 100%;

      .page_menu_inner {
        display: flex;
        flex-direction: column;
        padding: 28px 40px 0px 40px;
        border-bottom: 1px solid var(--theme-border-1);

        .page_menu_item {
          position: relative;
          display: flex;
          width: 248px;
          height: 64px;
          padding: 11px 20px;
          border-radius: 24px;
          align-items: center;
          cursor: pointer;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin-bottom: 18px;
          color: var(--theme-color-main);

          .page_menu_item_delete {
            display: none;
            position: absolute;
            right: 18px;
          }

          &.page_menu_item_active {
            background-color: var(--theme-bg2);

            .page_menu_item_delete {
              display: block;
            }
          }

          &:hover {
            background-color: var(--theme-bg2);

            .page_menu_item_delete {
              display: block;
            }
          }

          // &:last-child {
          //   padding-bottom: 0;
          // }

          .page_menu_item_logo {
            display: block;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            object-fit: cover;
          }

          .page_menu_item_title {
            width: 160px;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
            padding-left: 12px;
          }

          .page_menu_item_next {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            width: 160px;
            color: var(--theme-color-2);
            margin-top: 4px;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
            padding-left: 12px;
          }
        }
      }
    }

    .page_menu_title {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .page_context {
    flex: 1;
    width: calc(100% - 260px);

    .page_context_tab {
      width: 100%;
      display: flex;
      height: 56px;
      border-bottom: 1px solid var(--theme-border-1);

      .add_box {
        width: 48px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid var(--theme-border-1);
        cursor: pointer;
      }

      .page_context_tab_item {
        width: 248px;
        height: 56px;
        padding: 0 18.5px;
        display: flex;
        align-items: center;
        border-right: 1px solid var(--theme-border-1);
        cursor: pointer;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: var(--theme-color-main);

        div {
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }

        .gpt_name {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          color: var(--theme-color-2);
          zoom: 0.95;
        }

        &.page_context_tab_item_active {
          background: var(--theme-bg2);
          border-bottom: 1px solid var(--theme-border-1);
        }

        .page_context_tab_logo {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 7.5px;
        }

        .page_context_tab_close {
          margin-left: auto;
          cursor: pointer;
          display: flex;
          align-items: center;

          .page_context_tab_close_icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .skeleton_menu {
    width: 100%;
    opacity: 1;
    transition: all 0.3s;

    .fadeOut {
      height: 0;
      opacity: 0;
    }
  }

  .skeleton_tab {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 100%;
    height: 56px;
    opacity: 1;
    transition: all 0.3s;

    .fadeOut {
      height: 0;
      opacity: 0;
    }

    .skeleton_tab_avatar {
      margin: 20px;
    }
  }

  .skeleton_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    transition: all 0.3s;

    .fadeOut {
      height: 0;
      opacity: 0;
    }

    .skeleton_title {
      display: flex;
      align-items: center;
      justify-content: center;

      .skeleton_title_avatar {
        margin-right: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .skeleton_questions {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
    }

    .skeleton_hints {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 1000px;
      margin-top: auto;
      margin-bottom: 10px;

      .skeleton_hint {
        margin: 5px;
        width: 400px;
      }
    }
  }
}

.showTopic {
  width: 260px;
  min-width: 260px;
}

.hideTopic {
  margin: 0 !important;
  min-width: 0px;
  width: 0px;
  padding: 0 !important;
  overflow: hidden !important;
  border: none !important;
}

@media screen and (max-width: 450px) {
  .chat_box {
    height: calc(100vh) !important;
  }
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialogue{
  position: absolute;
  top: 20px;
  text-align: center;
  line-height: 28px;
  left: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: "pointer";
  width: 26px;
  height: 28px;
  background: #fff;
  filter:drop-shadow(0px 2px 4px rgba(28, 33, 42, 0.06)) drop-shadow(0px 4px 8px rgba(28, 33, 42, 0.10));
  fill: color(display-p3 1 1 1);
}
.dialogue_m{
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  box-shadow: 0px 2px 4px -2px #1D21290F;
  box-shadow: 0px 4px 8px -2px #1D21291A;
  z-index: 10;
}
.sider {
  background-color: var(--theme-bg) !important;
  transition: all 0.3s ease-in-out !important;
  display: flex;
  flex-direction: column;
  width: 250px;
  border-right: 1px solid var(--theme-border-1);
  max-height: calc(100vh - 64px);

  :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 44px;
  }

  :global(.ant-layout-sider-zero-width-trigger) {
    background: #fff;
    color: #272b30;
    top: 60px;
    z-index: 6;
    box-shadow: (0px 4px 8px rgba(28, 33, 42, 0.2));
    right: -27px;
    width: 26px;
    height: 28px;
  }

  .sider_menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;

    .sider_label {
      color: var(--theme-color-2);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 8px;
      width: 100%;
      padding: 0 16px 0 46px;
    }

    .sider_menu_item_wrap {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 0px 12px;
    }

    .sider_menu_item {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      margin-bottom: 8px;
      padding: 0px 16px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      color: var(--B-icon-color-icon-1, #1b212b);

      .sider_menu_item_icon {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      &.sider_menu_item_active {
        transition: all 0.25s ease-in-out;
        background: var(--B-bg-color-bg-4, rgba(27, 33, 43, 0.1));
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: var(--B-icon-color-icon-1, #1b212b);

        .label {
          color: var(--theme-color-main);
          font-weight: 600;
        }
      }

      &:hover {
        background: var(--B-bg-color-bg-4, rgba(27, 33, 43, 0.1));
        color: var(--theme-color-main);

        .label {
          color: var(--theme-color-main);
          font-weight: 600;
        }
      }
    }

    .sider_menu_item_disabled {
      width: 168px;
      height: 48px;
      padding: 0px 20px;
      border-radius: 12px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      opacity: 0.3;

      .sider_menu_item_icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      cursor: not-allowed;
    }
    a {
      color: var(--theme-color-2);
    }

    .label {
      transition: all 0.25s ease-in-out;
      color: var(--B-text-color-text-3, #81909e);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .bottom_box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ICPFiling {
    position: absolute;
    left: 44px;
    bottom: 10px;
    cursor: pointer;
    color: var(--B-text-color-text-2, #c7cdd6);
    // color: var(--B-text-color-text-2, color(display-p3 0.7882 0.8039 0.8314));
    font-family: "PingFang SC";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 183.333% */
  }

  .user_info {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    padding: 0 8px;
    position: relative;
    margin-top: 38px;
    width: 136px;

    &:hover {
      background: var(--theme-bg2);
      color: var(--theme-color-main);
      font-weight: 600;
      border-radius: 12px;
      .logout {
        display: flex;
      }
    }

    .logout {
      color: var(--theme-color-main);
      background-color: var(--theme-bg);
      display: flex;
      align-items: center;
      border-radius: 12px;
      padding: 0 20px;
      width: 168px;
      height: 56px;
      position: absolute;
      top: -56px;
      left: -16px;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      box-shadow: var(--theme-logout-shadow);
      z-index: 2;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      border: 1px solid var(--theme-border-6);
      display: none;

      &:hover {
        display: flex;
      }

      img {
        width: 18.5px;
        height: 18.5px;
        margin-right: 14px;
      }

      &:hover {
        color: var(--theme-color-main);
      }
    }

    .avator {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 12px;
    }

    .username {
      color: var(--theme-color-2);
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; //溢出不换行
      font-weight: 400;
    }

    .login {
      color: var(--theme-color-2);
      &:hover {
        text-decoration: underline;
      }
    }

    .register {
      color: var(--theme-color-2);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.menuCollapse {
  position: absolute;
  left: 12px;
  top: -48px;
  z-index: 10;
  color: #1d2129;
  border-radius: 4px;
  padding: 9px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: var(--B-bg-color-bg-4, rgba(27, 33, 43, 0.1));
  }
}

.textLabel {
  transition: opacity 1s ease-in-out, width 1s ease-in-out !important;
  word-break: keep-all;
}

.hideTextLabel {
  word-break: keep-all;
  opacity: 0;
  width: 0;
  transition: all 0.3s ease-in-out !important;
}

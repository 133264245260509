.wokePage {
  width: 100%;
  :global(.ant-modal-body) {
    min-height: 500px;
    max-height: 66vh;
    overflow: hidden;
  }
  :global(.ant-modal-footer) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  :global(.ant-modal-content) {
    background: var(--B-bg-color-bg-2, #f7f8fa);
  }
  :global(.ant-modal-title) {
    background: var(--B-bg-color-bg-2, #f7f8fa);
  }
  .header {
    display: flex;
    justify-content: space-between;
    .category {
      display: flex;
      margin-top: 5px;
      width: 100%; /* 或者 max-width: 100%; */
      flex-wrap: wrap; /* 在移动设备上允许弹性盒子内部的自动换行 */
      overflow-wrap: break-word;
      .category_item {
        border: 1px solid var(--B-border-color-border-1, #f2f3f5);
        padding: 6px 11px 6px 11px;
        text-align: center;
        border-radius: 4px;
        margin-right: 8px;
        color: #4e5969;
        font-size: 12px;
        background: #fff;
        cursor: pointer;
        margin-bottom: 3px;
      }
      .category_item_active {
        border: 1px solid var(--B-border-color-border-1, #007890);
        color: #007890;
      }
    }

    .input {
      width: 240px;
      height: 32px;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .body {
    height: 60vh;
    overflow-y: scroll;
    .page_recommand {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 24px;
      .page_recommand_item {
        margin-right: 16px;
        margin-bottom: 16px;
        max-width: calc(33% - 32px);
        min-width: 282px;
      }
    }
  }
}
.bottom {
  width: 100%;
  height: 400px;
}
@media screen and (max-width: 480px) {
  .header {
    position: relative;
    height: 114px;
    .category {
      position: absolute;
      top: 60px;
      margin-top: 0px !important;
      overflow: hidden;
      overflow-x: scroll;
      white-space: nowrap;
      flex-wrap: nowrap !important;
      overflow-wrap: normal !important;
    }
     .input {
      position: absolute;
      top: 8px;
      width: 267px !important;
    }
  }
  .body {
    .page_recommand {
      margin: 0 24px !important;
      .page_recommand_item {
        margin-right: 16px;
        margin-bottom: 16px;
        max-width: calc(60% - 20px) !important;
        min-width: 282px;
      }
    }
  }
}
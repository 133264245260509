.space {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  padding-left: 24px;
  min-width: 1200px;

  .space_item {
    margin-right: 36px;
    margin-bottom: 28px;
    width: calc(25% - 36px);
    color: var(--theme-color-main);
    cursor: pointer;

    &:nth-child(4n) {
      margin-right: 0px;
    }

    .space_item_padding {
      padding-top: 66.7%;
      height: 0;
      width: 100%;
    }

    .space_item_context {
      width: 100%;
      height: auto;
      color: var(--theme-color-main);
      position: relative;
      background-color: var(--theme-bg2);
      border-radius: 12px;
      overflow: hidden;

      .image_box {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
        }

        .member_box {
          position: absolute;
          left: 16px;
          top: 16px;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          padding: 0 16px 0 10px;
          height: 28px;
          background: var(--theme-bg5);
          color: var(--theme-color-7);
          border-radius: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          font-weight: 500;

          img {
            width: 22px;
            height: 22px;
            margin-right: 2px;
            position: relative;
          }

          div {
            background-image: var(--theme-color-5);
            -webkit-background-clip: text;
            color: transparent;
            font-weight: 600;
            margin-right: 2px;
          }
        }

        // .member_box {
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        // }
      }

      .space_item_info {
        height: 147px;
        border-radius: 0px 0px 12px 12px;
        display: flex;
        position: relative;
        width: 100%;
        padding: 0 18px;

        .space_item_info_count {
          display: flex;
          position: absolute;
          bottom: 18px;
          color: var(--theme-color-7);
          align-items: center;

          .space_item_info_count_item {
            font-size: 12px;
            font-style: normal;
            line-height: 140%;
            // padding: 0 12px;
            height: 28px;
            // background: var(--theme-bg5);
            color: var(--theme-color-2);
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            font-weight: 500;
            white-space: nowrap;

            img {
              margin-right: 4px;
              width: 16px;
              height: 16px;
              // left: -1000px;
              // filter: drop-shadow(var(--theme-color-2) 1000px 0);
            }

            span {
              margin-right: 4px;
            }
          }
        }

        .space_item_info_content {
          margin-top: 41px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .space_item_info_name {
          position: absolute;
          left: 79px;
          top: 9px;
          color: var(--theme-color-2);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }

        .avator_img {
          width: 48px;
          height: 48px;
          position: absolute;
          left: 18px;
          top: -24px;
          border-radius: 12px;
          border: 4px solid var(--theme-border-3);
          object-fit: cover;
        }
      }
    }
  }

  .skeleton {
    .skeleton_info {
      height: 38px;
      margin-top: 12px;
      display: flex;
      align-items: center;

      .skeleton_info_name {
        margin-left: 8px;
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 76px;
    margin-top: 2px;
    padding-right: 44px;

    :global(.ant-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;
      :global(.ant-pagination-item) {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        background-color: var(--theme-bg2);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :global(a) {
          color: var(--theme-color-main);
        }
      }

      :global(.ant-pagination-item-active) {
        background-color: var(--theme-bg6);
        :global(a) {
          color: var(--theme-color-6);
        }
      }

      :global(.ant-pagination-item-ellipsis) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link-icon) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-disabled) {
        :global(.ant-pagination-item-link) {
          color: var(--theme-color-4);
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .space {
    .space_item {
      width: calc(33% - 36px);
      margin-right: 36px;
      margin-bottom: 28px;

      &:nth-child(4n) {
        margin-right: 36px !important;
      }

      &:nth-child(3n) {
        margin-right: 0px !important;
      }
    }
  }
}

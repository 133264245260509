.category {
    height: 64px;
    border-bottom: 1px solid var(--theme-border-1);
    display: flex;
    position: sticky;
    top: 0;
    background-color: var(--theme-bg);
    z-index: 4;
    justify-content: space-between;
}

.cates {
    height: 64px;
    border-bottom: 1px solid var(--theme-border-1);
    display: flex;
    padding-left: 24px;
    background-color: var(--theme-bg);
    z-index: 4;
    justify-content: flex-end;
}

@media screen and (max-width : 600px) {
    .cates {
        padding-left: 0;
    }
}

.category_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    cursor: pointer;
    color: var(--theme-color-2);
    position: relative;
    font-weight: 500;
}

.category_item:hover {
    color: var(--theme-color-main);
}

.category_name.category_name_active {
    color: var(--theme-color-main);
}

.category_item.category_item_disabled:hover {
    color: var(--theme-color-2);
    cursor: not-allowed;
}

.category_item_name_box {
    display: flex;
    width: 100%;
    align-items: center;
    word-wrap: no-wrap;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
}

.category_img {
    margin-right: 4px;
    overflow: hidden;
    position: relative;
    left: -1000px;
    filter: drop-shadow(var(--theme-color-2) 1000px 0);
}

.category_img.category_img_active {
    filter: drop-shadow(var(--theme-color-main) 1000px 0);
}

.category_item_bottom {
    height: 2px;
    background-color: var(--theme-bg);
    width: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    transition: all 0.2s ease-in-out;

    &.category_item_bottom_active {
      width: 100%;
      background: var(--theme-color-10);
    }
  }

.btn {
    height: 100%;
    margin-right: 25px;
    display: flex;
    align-items: center;
}

.btnWrapper {
    box-shadow: none;
}

.container {
    width: 100%;
}

.btns {
    display: flex;
    gap: 16px;
    padding: 24px 24px;
    overflow: hidden;
    flex-wrap: wrap;
}

.button {
    // width: 100%;
    text-align: center;
    box-shadow: none;
}

.selectedMenuItem {
    background-color: #007890;
    color: #fff !important;
}
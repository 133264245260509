.scroll-hide {
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  & {
    -ms-overflow-style: none;
  }

  & {
    overflow: -moz-scrollbars-none;
  }
}

.g6-component-tooltip {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 12px;
  color: #000;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 8px;
  box-shadow: rgb(174, 174, 174) 0px 0px 10px;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  // line-height: 1;
}

blockquote {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
}

li {
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 180%;
  list-style-position: outside;
  text-align: left;
  white-space: unset;
  p {
    font-weight: 400;
  }

  ::marker {
    font-weight: 400;
  }
}

ol {
  margin-block-start: 0;
  margin-block-end: 0;
  list-style-position: outside;
  white-space: unset;

  p {
    font-weight: 600;
  }

  ::marker {
    font-weight: 600;
  }
}

.ant-layout {
  font-family: "Hind" !important;
}

.ant-popover {
  --antd-arrow-background-color: var(--theme-bg2) !important;
}

.ant-popover-inner {
  background-color: var(--theme-bg2) !important;
  padding: 0 !important;
}

.ant-tooltip {
  .ant-tooltip-inner {
    background-color: transparent !important;
    color: var(--tooltip-text-color) !important;
    font-size: 14px !important;
  }
  background-color: var(--tooltip-bg-color) !important;
  color: var(--tooltip-text-color) !important;
  font-size: 14px !important;
  border-radius: 6px !important;

  &:where(.css-dev-only-do-not-override-17e1ing).ant-tooltip
    .ant-tooltip-arrow:before {
    background-color: var(--tooltip-bg-color) !important;
  }

  .ant-tooltip-arrow {
    display: none;
  }
}

.page {
  height: calc(100vh - 80px);
  overflow-y: scroll;

  .category {
    height: 64px;
    border-bottom: 1px solid var(--theme-border-1);
    display: flex;
    padding-left: 24px;
    // margin-top: 28px;
    position: sticky;
    top: 0;
    background-color: var(--theme-bg);
    z-index: 4;

    .category_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 44px;
      cursor: pointer;
      color: var(--theme-color-2);
      position: relative;
      font-weight: 500;

      &:hover {
        color: var(--theme-color-main);
      }

      .category_name {
        &.category_name_active {
          color: var(--theme-color-main);
        }
      }

      &.category_item_disabled {
        &:hover {
          color: var(--theme-color-2);
          cursor: not-allowed;
        }
      }

      .category_item_name_box {
        display: flex;
        width: 100%;
        align-items: center;
        word-wrap: no-wrap;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
      }

      .category_img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        overflow: hidden;
        position: relative;
        left: -1000px;
        filter: drop-shadow(var(--theme-color-2) 1000px 0);

        &.category_img_active {
          filter: drop-shadow(var(--theme-color-main) 1000px 0);
        }
      }

      .category_item_bottom {
        height: 2px;
        background-color: var(--theme-bg);
        width: 0;
        bottom: 0;
        position: absolute;
        left: 0;
        transition: all 0.2s ease-in-out;

        &.category_item_bottom_active {
          width: 100%;
          background: var(--theme-color-10);
        }
      }
    }
  }

  .page_header {
    // margin-bottom: 40px;
  }

  .page_list_item_add {
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid #333;
    width: calc(100% - 30px);
    border-radius: 12px;
    cursor: pointer;
    color: var(--theme-color-main);
    margin: 24px 24px 0;
    border-width: 0;

    .page_list_item_add_inner {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      background-color: var(--theme-bg);
      gap: 8px;
      border-radius: 12px;
      .img {
        width: 20px;
        height: 20px;
        background: var(--theme-color-10);
        border-radius: 50%;
        color: #fff;
        line-height: 24px;
        text-align: center;
      }

      span {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }

    div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .sub_total {
    margin-top: 44px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-left: 80px;

    span {
      color: var(--theme-color-main);
    }
  }

  .radio__wrap {
    display: flex;
    gap: 30px;
    margin-top: 12px;
    margin-left: 24px;
    :global(.ant-radio-button-wrapper-checked) {
      color: var(--theme-color-main);
      background: var(--theme-bg2);
    }
  }
  .radioBtn {
    background: var(--theme-bg);
    border-radius: 22px;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #a7a7a7;
    border-width: 0;
    &::before {
      display: none;
    }
    &:hover {
      background: var(--theme-bg2);
    }
  }

  .page_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 24px;
  }

  .create_entry_item_wrap,
  .page_recommand_item {
    margin-right: 32px;
    margin-bottom: 24px;
    width: calc(20% - 32px);
    max-width: 240px;

    &:nth-child(4n) {
      // margin-right: 0px;
    }
  }

  .create_entry_item_wrap {
    width: 33%;
    margin-right: 24px;
    max-width: none;
  }

  .page_list {
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 76px;
    margin-top: 2px;
    padding-right: 44px;

    :global(.ant-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;
      :global(.ant-pagination-item) {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        background-color: var(--theme-bg2);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :global(a) {
          color: var(--theme-color-main);
        }
      }

      :global(.ant-pagination-item-active) {
        background-color: var(--theme-bg6);
        :global(a) {
          color: var(--theme-color-6);
        }
      }

      :global(.ant-pagination-item-ellipsis) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link-icon) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-disabled) {
        :global(.ant-pagination-item-link) {
          color: var(--theme-color-4);
        }
      }
    }
  }

  .skeleton {
    .skeleton_info {
      height: 38px;
      margin-top: 12px;
      display: flex;
      align-items: center;

      .skeleton_info_name {
        margin-left: 8px;
      }
    }
  }
}

.create_entry_wrap {
  display: flex;
}

@media screen and (max-width: 1440px) {
  .page {
    .create_entry_item_wrap,
    .page_list_item {
      width: calc(33% - 44px);
      margin-right: 44px;
      margin-bottom: 28px;

      &:nth-child(4n) {
        margin-right: 44px !important;
      }

      &:nth-child(3n) {
        margin-right: 44px !important;
      }
    }
    .create_entry_item_wrap {
      width: calc(33%);
      margin-right: 24px;
    }
    .page_list {
    }
  }
}

.customClass {
  :global(.ant-message-notice-content) {
    padding: 0 !important;
  }

  :global(.ant-alert) {
    padding: 16px 24px;

    :global(.ant-alert-message) {
      text-align: left;
      margin-bottom: 2px;
      font-size: 18px;
      font-weight: bold;
      // color: var(--theme-color-main);
    }
    :global(.ant-alert-description) {
      // color: var(--theme-color-main);
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    .page_recommand_item {
      width: calc(33% - 32px);
      margin-right: 32px;
      margin-bottom: 24px;
      max-width: 240px;

      &:nth-child(4n) {
        margin-right: 28px !important;
      }

      &:nth-child(3n) {
        margin-right: 28px !important;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .page {
    .page_recommand_item {
      margin-right: 32px;
      margin-bottom: 24px;
      width: calc(25% - 32px);
      max-width: 240px;

      &:nth-child(4n) {
        margin-right: 28px !important;
      }

      &:nth-child(3n) {
        margin-right: 28px !important;
      }
    }
  }
}

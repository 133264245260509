.page { 
  background: #f8f9fb;
  height: 100%;
  min-width: 1200px;
  position: relative;
  .assistant_bgc {
    position: absolute;
    top: 0;
    left: 0;
  }

  .skeleton {
    .skeleton_info {
      height: 38px;
      margin-top: 12px;
      display: flex;
      align-items: center;

      .skeleton_info_name {
        margin-left: 8px;
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 76px;
    margin-top: 2px;
    padding-right: 44px;

    :global(.ant-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;
      :global(.ant-pagination-item) {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        background-color: var(--theme-bg2);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :global(a) {
          color: var(--theme-color-main);
        }
      }

      :global(.ant-pagination-item-active) {
        background-color: var(--theme-bg6);
        :global(a) {
          color: var(--theme-color-6);
        }
      }

      :global(.ant-pagination-item-ellipsis) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link-icon) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-disabled) {
        :global(.ant-pagination-item-link) {
          color: var(--theme-color-4);
        }
      }
    }
  }
}

.page_recommand {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 16px 24px 24px 24px;
  .page_recommand_item {
    margin-right: 16px;
    margin-bottom: 16px;
    max-width: calc(33% - 32px);
    min-width: 282px;
  }
}

@media screen and (max-width: 1500px){
  .page {
    .page_recommand_item {
      max-width: calc(33% - 12px) !important;
    }
  }
}

@media screen  and (max-width: 1600px){
  .page {
    .page_recommand_item {
      max-width: calc(33% - 32px);
    }
  }
}


@media screen and (min-width: 1700px) and (max-width: 1800px) {
  .page {
      .page_recommand_item {
        width: calc(25% - 32px);
      }
  }
}

:global(.content){
  background: #f8f9fb !important;
}
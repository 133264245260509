.iconWrap {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.uploadWrap {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0 0 40px;
  justify-self: center;
}
.uploadBtn {
  font-weight: 500;
  border: 1px solid #00d44b;
  border-radius: 8px;
  padding: 9px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: #00d44b;
}

.deleteAgentBtn,
.addAgent {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid var(--theme-border-1) !important;
  font-size: 14px;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 8px;
  background: var(--Linear, linear-gradient(90deg, #b7ff00 0%, #00fff1 100%));
  color: #252b32 ;
  &:disabled {
    background-color: var(--theme-bg8);
    color: var(--theme-color-2);
  }
}

.questionWrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.deleteAgentBtn {
  margin-top: 0;
  background: #f02 !important;
  color: var(--dot-color) !important;
}

.deleteAgent {
  display: flex;
  padding: 16px 20px !important;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
}

.modal {
  :global(.ant-btn-default) {
    color: var(--dot-color) !important;
    border: 1px solid var(--theme-border-1) !important;
    font-weight: 500;
    font-size: 14px;
    box-shadow: none !important;
    background: transparent !important;
  }
  :global(.ant-btn-primary) {
    background-color: var(--theme-color-10) !important;
    border: none !important;
    font-weight: 500;
    font-size: 14px;
    color: var(--theme-bg) !important;
    box-shadow: none !important;
  }
}
$item-height: 44px;
$gap: 20px;

.modal {
  overflow: hidden;
  padding: 4px !important;
  :global(.ant-modal-content) {
    border-radius: 20px;
    background: var(--theme-bg);
    color: var(--theme-color-2);
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.25);
  }

  :global(.ant-modal-header) {
    background: var(--theme-bg);
    color: var(--theme-color-main);
  }

  :global(.ant-modal-close-x) {
    color: var(--theme-color-main);
  }
  :global(.ant-modal-title) {
    color: var(--theme-color-main);
  }
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-color-main);
  flex-direction: column;
  .title {
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    color: var(--theme-bg6);
    margin-bottom: 18px;
    margin-top: 30px;
  }
  img{
    width: 200px;
  }
  .tip {
    font-size: 16px;
    line-height: 24px;
    color: var(--theme-bg6);
    margin-top: 8px;
    margin-bottom: 88px;
  }
}

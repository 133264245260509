@keyframes fadein {
  0% {
    display: none;
    opacity: 0;
    transform: scale(0.4);
  }
  1% {
    display: flex;
    opacity: 0.2;
    // transform: scale(0.7);
  }
  10% {
    opacity: 0.3;
    // transform: scale(0.75);
  }
  25% {
    opacity: 0.4;
    // transform: scale(0.8);
  }
  40% {
    opacity: 0.55;
    // transform: scale(0.85);
  }
  50% {
    opacity: 0.65;
    // transform: scale(0.88);
  }
  60% {
    opacity: 0.75;
    // transform: scale(0.92);
  }
  75% {
    opacity: 0.9;
    // transform: scale(0.97);
  }
  100% {
    opacity: 1;
    display: flex;
    transform: scale(1);
  }
}

.card_detail {
  width: 100vw;
  height: 100vh;
  background-color: var(--theme-bg-mask-2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  display: none;

  .card_details_box {
    display: flex;
    animation: fadein 0.35s infinite ease-in-out;
    animation-iteration-count: 1;
  }

  .card_details_close {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-bg2);
    margin-left: 12px;
    align-self: flex-start;
    overflow: hidden;
    cursor: pointer;
  }

  .card_details_inner {
    width: 80vw;
    height: 80vh;
    min-width: 1252px;
    min-height: 650px;
    max-width: 1252px;
    max-height: 836px;
    width: 100%;
    height: 100%;
    background-color: var(--theme-bg2);
    border-radius: 16px;
    display: flex;
    overflow: hidden;
    position: relative;

    .card_image_index {
      position: absolute;
      padding: 2px 10px;
      right: 12px;
      top: 13px;
      background-color: var(--theme-bg-mask);
      z-index: 2;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: var(--theme-color-7);
    }

    .contentStyle {
      margin: 0;
      color: #fff;
      height: calc(80vh - 24px);
      min-height: calc(650px - 24px);
      font-size: 24px;
      text-align: center;
      // background: #364d79;
      background: var(--theme-bg);
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:focus-visible {
        outline: none !important;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        cursor: zoom-in;
        &:focus-visible {
          outline: none !important;
        }
      }
    }

    .card_image_box {
      width: 50%;
      position: relative;
      background: #fff;
      border-radius: 12px;
      border: 1px solid var(--theme-border-1);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 16px;
      overflow: hidden;

      .card_image_box_close {
        display: none;
      }

      &.card_image_box_fullscreen {
        width: 100vw;
        height: 100vh;
        position: fixed;
        padding: 0;
        z-index: 99;
        left: 0;
        top: 0;
        margin: 0;
        border-radius: 0;

        .card_image_box_inner {
          border-radius: 0;
          height: 100vh;

          .card_image_box_close {
            display: block;
            position: absolute;
            top: 24px;
            left: 24px;
            font-size: 18px;
            z-index: 2;
            color: var(--theme-bg6);
          }

          .card_image_index {
            top: 24px;
            right: 24px;
          }

          .left_arrow {
            cursor: pointer;
            left: 24px;
          }

          .right_arrow {
            right: 24px;
            cursor: pointer;
          }

          .contentStyle {
            height: 100vh;
            min-height: 100vh;
            padding: 0 50px;

            img {
              cursor: zoom-out;
              &:focus-visible {
                outline: none;
              }
            }
          }
        }
      }

      .card_image_box_inner {
        width: 100%;
        height: calc(80vh - 24px);
        min-height: calc(650px - 24px);
        overflow: hidden;
        border-radius: 12px;

        .left_arrow {
          position: absolute;
          top: 50%;
          left: 12px;
          transform: translate(0, -50%);
          opacity: 0;
          cursor: pointer;
          transition: 0.3s all;
        }
        .right_arrow {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          right: 12px;
          opacity: 0;
          cursor: pointer;
          transition: 0.3s all;
        }

        &:hover {
          .left_arrow {
            z-index: 2;
            display: block;
            opacity: 1;
          }

          .right_arrow {
            z-index: 2;
            display: block;
            opacity: 1;
          }
        }
      }
    }

    .card_chat_box {
      width: 50%;
      padding: 12px 24px 12px 12px;

      .card_chat_box_inner {
        border-radius: 12px;
        overflow: hidden;
        height: 100%;
        min-height: calc(650px - 24px);
      }
    }
  }

  &.card_detail_show {
    display: flex;
  }

  :global(.ant-carousel .slick-slider) {
    position: inherit;
  }

  :global(.ant-carousel .slick-dots-bottom) {
    left: 50%;
    transform: translate(-50%, 0);
    width: fit-content;
    padding: 8px 8px;
    border-radius: 999px;
    &:hover {
      opacity: 1;
      background: rgba(39, 43, 48, 0.2);
    }

    li {
      height: 6px;
      width: 14px;
      button {
        height: 6px;
      }
    }

    :global(.slick-active) {
      width: 20px !important;
    }
  }
}

.switchWrap {
  position: absolute;
  display: flex;
  width: fit-content;
  justify-content: center;
  border-radius: 8px;
  padding: 4px;
  background: var(--B-color-bg-3, rgba(0, 0, 0, 0.04));
  z-index: 2;
  top: 28px;
  left: 28px;
}

.activeTab {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: #272b30;
  width: 84px;
  height: 28px;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
}

.normalTab {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  width: 84px;
  color: #86909c;
  white-space: nowrap;
  height: 28px;
  cursor: pointer;
}

.card_video_box {
  margin: 16px;
  width: 50%;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid var(--theme-border-1);
  border-radius: 12px;
}

.card_documents {
  overflow: hidden;
  width: 50%;
  margin: 16px;
  padding: 0px;
  background: #fff;
  border: 1px solid var(--theme-border-1);
  border-radius: 12px;
  :global(.textLayer) {
    // display: none !important;
  }

  :global(.react-pdf__Page) {
    display: flex;
    justify-content: center;
  }
}

.card_documents_item {
  // cursor: zoom-in;
  margin-top: 54px;
  height: 100%;
  overflow: auto;
}

.pdfMagnifier {
  cursor: auto;
  width: 100vw;
  height: 100vh;
  position: fixed;
  padding: 0;
  z-index: 99;
  left: 0;
  top: 0;
  margin: 0;
  border-radius: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  transform: scale(1);

  :global(.react-pdf__Page__annotations) {
    width: fit-content;
  }

  .card_pdf_box_close {
    display: block;
    position: fixed;
    top: 24px;
    left: 24px;
    font-size: 18px;
    z-index: 2;
    color: var(--theme-bg6);
  }
}

.icon_close_white {
  color: #fff !important;
}

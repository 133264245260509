.page {
  height: calc(100vh - 80px);
  overflow-y: scroll;

  .category {
    height: 64px;
    border-bottom: 1px solid var(--theme-border-1);
    display: flex;
    padding-left: 24px;
    // margin-top: 28px;
    position: sticky;
    top: 0;
    background-color: var(--theme-bg);
    z-index: 4;

    .category_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      cursor: pointer;
      color: var(--theme-color-2);
      position: relative;
      font-weight: 500;

      &:hover {
        color: var(--theme-color-main);
      }

      .category_name {
        &.category_name_active {
          color: var(--theme-color-main);
        }
      }

      &.category_item_disabled {
        &:hover {
          color: var(--theme-color-2);
          cursor: not-allowed;
        }
      }

      .category_item_name_box {
        display: flex;
        width: 100%;
        align-items: center;
        word-wrap: no-wrap;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
      }

      .category_img {
        margin-right: 4px;
        overflow: hidden;
        position: relative;
        left: -1000px;
        filter: drop-shadow(var(--theme-color-2) 1000px 0);

        &.category_img_active {
          filter: drop-shadow(var(--theme-color-main) 1000px 0);
        }
      }

      .category_item_bottom {
        height: 2px;
        background-color: var(--theme-bg);
        width: 0;
        bottom: 0;
        position: absolute;
        left: 0;
        transition: all 0.2s ease-in-out;

        &.category_item_bottom_active {
          width: 100%;
          background: var(--theme-color-10);
        }
      }
    }
  }

  .page_header {
    // margin-bottom: 40px;
  }

  .page_list {}

}

.create_entry_wrap {
  display: flex;
  gap: 64px;
}

@media screen and (max-width: 1440px) {
  .page {
    .page_list_item {
      width: calc(33% - 44px);
      margin-right: 44px;
      margin-bottom: 28px;

      &:nth-child(4n) {
        margin-right: 44px !important;
      }

      &:nth-child(3n) {
        margin-right: 44px !important;
      }
    }

    .page_list {}
  }
}

.page_list_item_add {
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(30% - 30px);
  border-radius: 12px;
  cursor: pointer;
  color: var(--theme-color-main);
  margin: 20px 24px;
  border-width: 0;

  .page_list_item_add_inner {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: var(--theme-bg2);
    gap: 8px;
    border-radius: 12px;

    .img {
      width: 20px;
      height: 20px;
      background: var(--theme-color-10);
      border-radius: 50%;
      color: #fff;
      line-height: 24px;
      text-align: center;
    }

    span {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }

  div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.page {
  height: 100%;
  display: flex;
  position: relative;

  .page_context {
    flex: 1;
    width: calc(100% - 260px);
  }
}

.topicList {
  z-index: 2;
  height: calc(100% - 64px);
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  background-color: #fff;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  border: 1px solid #eee;
  border-bottom: none;
  transition: width 0.5s ease-in-out, padding 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  align-self: flex-start;
}

.topicListHeader {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 25%;
  padding: 16px 16px;
  font-size: 16px;
  color: #252b32;
  font-weight: 500;
  justify-content: flex-end;
}

.topicListContent {
  height: calc(100% - 68px - 16px);
  min-height: calc(100% - 68px - 16px);
  max-height: calc(100% - 68px - 16px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 0 16px 16px;
  margin-bottom: 16px;
  overflow: scroll;
}

.topicItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 12px;
  align-items: flex-start;
  gap: 6px;
  background-color: #f7f7f7;
  color: #252b32;
  border-radius: 8px;
  cursor: pointer;
}

.showTopic {
  width: 260px;
  min-width: 260px;
}

.hideTopic {
  margin: 0 !important;
  min-width: 0px;
  width: 0px;
  padding: 0 !important;
  overflow: hidden !important;
  border: none !important;
}

@media screen and (max-width: 450px) {
  .page {
    height: 100vh;
  }
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: "nowrap";
  overflow: "hidden";
  text-overflow: "ellipsis";
  width: 200;
  font-size: 14;
}

.time {
  color: "#7D868F";
  font-size: 12px;
}

.dialogue {
  position: absolute;
  top: 20px;
  text-align: center;
  line-height: 28px;
  left: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: "pointer";
  width: 26px;
  height: 28px;
  background: #fff;
  filter: drop-shadow(0px 2px 4px rgba(28, 33, 42, 0.06))
    drop-shadow(0px 4px 8px rgba(28, 33, 42, 0.1));
  fill: color(display-p3 1 1 1);
}
.dialogue_m {
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  box-shadow: 0px 2px 4px -2px #1d21290f;
  box-shadow: 0px 4px 8px -2px #1d21291a;
  z-index: 10;
}

$img-height: 200px;

.upload {
  display: flex;
  width: 100%;
  :global(.ant-upload-wrapper) {
    width: 100% !important;
    max-width: 300px;
    height: $img-height;
  }
  //   :global(.ant-upload-select) {
  //     width: 100% !important;
  //     max-width: 300px;
  //   }

  //   img {
  //     width: unset !important;
  //     height: 100% !important;
  //   }
}

.upload_card_inside,
.upload_inside {
  position: relative;
  width: 247.654px;
  height: 169px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
}

.upload_card_inside {
  width: 150px;
  height: 200px;
}

.closeIcon_hide {
  display: none;
}

.bg_img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 12px;
  // background-color: var(--theme-bg2);
}

.add {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--theme-bg2);
  overflow: hidden;
  border-radius: 12px;

  .addIcon {
    width: 20px;
    height: 20px;
    background: var(--theme-color-10);
    border-radius: 50%;
    color: #fff;
    line-height: 24px;
    text-align: center;
  }

  .addText {
    margin-top: 8px;
    color: var(--theme-color-main);
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.closeIcon {
  text-align: center;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0px;
  top: 0px;
  color: rgb(255, 255, 255);
  background: #000;
  opacity: 0.75;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

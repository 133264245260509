.page {
  // padding: 0 24px;
  height: 100%;

  .category {
    height: 64px;
    border-bottom: 1px solid var(--theme-border-1);
    display: flex;
    padding-left: 24px;
    // margin-top: 28px;
    position: sticky;
    top: 0;
    background: var(--theme-bg);
    z-index: 5;

    .category_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      cursor: pointer;
      color: var(--theme-color-2);
      position: relative;
      font-weight: 500;

      &:hover {
        color: var(--theme-color-main);
      }

      .category_name {
        &.category_name_active {
          color: var(--theme-color-main);
        }
      }

      .category_item_name_box {
        display: flex;
        width: 100%;
        align-items: center;
        word-wrap: no-wrap;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
      }

      .category_img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        overflow: hidden;
        position: relative;
        left: -10000px;
        filter: drop-shadow(var(--theme-color-2) 10000px 0);

        &.category_img_active {
          filter: drop-shadow(var(--theme-color-main) 10000px 0);
        }
      }

      .category_item_bottom {
        height: 2px;
        background-color: var(--theme-bg);
        width: 0;
        bottom: 0;
        position: absolute;
        left: 0;
        transition: all 0.2s ease-in-out;

        &.category_item_bottom_active {
          width: 100%;
          background: var(--theme-color-10);
        }
      }
    }
  }
}

.droWrap {
  display: flex;
}

.board {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.uploadText,
.cardTextWrap {
  display: inline-flex;
  color: var(--theme-color-main);
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  gap: 8px;
  line-height: 22px; /* 122.222% */

  img {
    width: 20px;
    height: 20px;
  }
}

.uploadText {
  cursor: pointer;
  .img {
    width: 20px;
    height: 20px;
    background: var(--theme-color-10);
    border-radius: 50%;
    color: #fff;
    line-height: 24px;
    text-align: center;
  }
}

.cardLimitTip {
  color: var(--theme-color-2);
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.onNext,
.onPre {
  width: 0;
  overflow: hidden;
  position: absolute;
  height: 100%;
  background: linear-gradient(90deg, var(--theme-bg) 0%, transparent 100%);
  z-index: 100;
  display: flex;
  justify-content: start;
  align-items: center;
}

.onNext {
  top: 0;
  right: 0px;
  background: linear-gradient(90deg, var(--theme-bg) 0%, transparent 100%);
  transform: rotate(180deg);
}

.onPreIcon {
  color: var(--theme-bg);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.show {
  padding-left: 16px;
  width: 80px;
  overflow: hidden;
  animation: widthGrow 0.25s ease-in-out;
}

.hide {
  padding: 0;
  width: 0;
  overflow: hidden;
  animation: widthHide 0.25s ease-in-out;
}

@keyframes widthGrow {
  from {
    width: 0;
  }
  to {
    width: 80px;
  }
}

@keyframes widthHide {
  from {
    width: 80px;
    padding-left: 16px;
  }
  to {
    width: 0;
    padding: 0;
  }
}

.page {
  :global(.ant-modal-body) {
    min-height: 500px;
    max-height: 66vh;
    overflow: hidden;
  }
  :global(.ant-modal-footer) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footerCount {
  color: #007890;
  margin: 0 4px;
  display: inline-block;
}

.footerBtns {
  display: flex;
  gap: 8px;
}

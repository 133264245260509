.chat_super_assistant_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  align-items: center;

  .chat_input_box_wrap {
    display: flex;
    z-index: 1;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 720px;
    left: 50%;
    transform: translate(-50%);
    align-items: center;
    position: relative;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
    border: var(--Size-Line-Width-lineWidth, 1px) solid var(--B-border-color-border-1, #f2f3f5);
    box-shadow: 0px 20px 24px -4px rgba(28, 33, 42, 0.08),
      0px 8px 8px -4px rgba(28, 33, 42, 0.03);
  }

  .chat_input_active {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid transparent;
    background: var(--theme-bg);
    padding: 11px 16px 48px;
    background: var(--theme-bg);
  }

  .chat_insert_input {
    position: relative;
    background: var(--theme-bg);
    // width: 738px;
    height: 56px;
    border: none;
    border-radius: 0;
    color: var(--theme-color-main);
    padding: 3px 36px 3px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    &::-webkit-scrollbar {
      display: none;
      /* Chrome Safari */
    }

    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }

    &:focus-within {
      box-shadow: none;
    }
  }

  .chat_insert_input::placeholder {
    color: var(--theme-color-2);
  }

  .agentBtn,
  .agentBtnActive {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--theme-color-9);
    padding: 0;
    box-shadow: none;
    border: 1px solid var(--B-border-color-border-1, #f2f3f5);

    &:hover {
      color: black !important;
      background: rgba(0, 0, 0, 0.1) !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }

  .agentBtnNumbers {
    font-size: 14px;
    color: var(--theme-color-10);
  }

  .agentBtnActive {
    border-radius: 45px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(90deg, #0fc 0%, #a917ff 100%);
  }

  .agentIcon {
    width: 20px;
    height: 20px;
  }

  .agentBtnContent {
    gap: 8px;
    height: 34px;
    justify-content: center;
    // background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    width: calc(100% - 2px);
    border-radius: 44px;
    padding: 8px 12px;
  }
}

.filesList {
  width: 100%;
  max-width: 100%;
  position: relative;
  top: -12px;
  overflow: hidden;
}

.chat_insert_tool {
  position: absolute;
  bottom: 11px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 22px);
  padding: 0 11px 0 0;
}

.chat_insert_right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.chat_upload,
.chat_workflow {
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.chat_insert_send {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat_to_bottom {
  position: absolute;
  overflow: hidden;
  top: -32px;
  transform: translate(-50%, -50%) rotate(180deg);
  border-radius: 50%;
  border: 1px solid var(--theme-border-1);
  width: 32px;
  height: 32px;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .filesList {
    right: 0;
    width: calc(100% + 15px);
  }

  .mobileChatInput {
    display: flex;
    flex-direction: column;

    // box-shadow: unset !important;
    // border: unset !important;
    // background: #e0e6e8;
    .filesList {
      top: unset;
      overflow: auto;
    }

    .chat_insert_right {
      bottom: unset;
    }
  }

  .chat_insert {
    width: calc(100% - 32px);
    align-self: center;
  }

  .chat_input_active {
    border-radius: 8px;
    box-shadow: 0px 20px 24px -4px rgba(28, 33, 42, 0.08),
      0px 8px 8px -4px rgba(28, 33, 42, 0.03);
    border: 1px solid #f2f3f5;
    position: relative;
    z-index: 2;
  }

  .chat {
    &.chat_home_page {
      min-width: 100vw !important;
      max-width: 100vw !important;
    }

    .chat_inner {
      min-width: 100vw !important;
    }

    .chat_history {
      height: calc(100vh - 135px);
      padding: 16px 20px;
    }
  }

  .chat_super_assistant_wrap {
    .chat_input_box_wrap {
      max-width: calc(100vw - 32px) !important;
      min-width: auto !important;
    }
  }
}

.chat_insert_left {
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 14px;
  color: #1d2129;
  line-height: 36px;
}

.chat_insert_dp {
  display: flex;
  align-items: center;
  gap: 6px;
}
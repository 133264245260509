.wrap {
    margin-top: 28px;
    border: 1px solid var(--theme-border-4);
    border-radius: 12px;
    overflow: hidden;
    :global(.ant-table-wrapper .ant-table-thead > tr > th) {
      background: transparent;
      border: 0;
      color: var(--theme-color-main);
    }
    :global(
        .ant-table-wrapper
          .ant-table-thead
          > tr
          > th:not(:last-child):not(.ant-table-selection-column):not(
            .ant-table-row-expand-icon-cell
          ):not([colspan])::before
      ) {
      width: 0;
    }
    :global(.ant-table-wrapper .ant-table) {
      background: var(--theme-bg);
    }
    :global(.ant-table-wrapper .ant-table-tbody >tr >td) {
      border: 0;
    }
    :global(.ant-table-wrapper .ant-table-tbody .ant-table-row > .ant-table-cell-row-hover) {
      background: transparent;
    }
  
    .tableText {
      color: var(--theme-color-main);
      span {
        cursor: pointer
      }
      
    }
  }
  
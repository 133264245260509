.page {
  font-size: 0px;
  line-height: 0px;

  .scrollableTable {
    width: 100%;
    overflow-x: scroll;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 100%;
  }

  ul,
  ol {
    height: fit-content;
    line-height: 0px;

    >* {
      font-size: initial;
      line-height: 175%;
    }

    >li:nth-child(n + 10):nth-child(-n + 99) {
      margin-inline-start: 28px;
    }
  }

  @mixin set-margin($start, $n) {

    ul[start="#{$start}"],
    ol[start="#{$start}"] {
      >li:nth-child(n + #{11 - $start}):nth-child(-n + #{100 - (10 - $start)}) {
        margin-inline-start: 28px;
      }
    }
  }

  @for $i from 1 through 10 {
    @include set-margin($i, 10 - $i + 1);
  }

  @for $i from 11 through 100 {

    ul[start="#{$i}"],
    ol[start="#{$i}"] {
      >li:nth-child(n) {
        margin-inline-start: 28px;
      }
    }
  }

  li {
    margin-inline-start: 20px;
    white-space: normal;
  }

  >* {
    font-size: initial;
    line-height: initial;

    li {
      line-height: 175%;
    }
  }

  > :last-child {
    margin-block-end: 0 !important;
  }
}

.sectionWrap {
  margin-bottom: 28px;
}

.title {
  height: 28px;
  font-weight: 500;
  color: var(--theme-color-9);
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.think {
  margin-bottom: 8px;
}



.answerContent {

  span,
  p {
    line-height: 175%;
  }

  :global(code) {
    overflow: auto;
  }

  :global(pre) {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }

    & {
      -ms-overflow-style: none;
    }

    & {
      overflow: -moz-scrollbars-none;
    }
  }

  img {
    max-width: 50%;
  }
}

.customSmallGrid,
.customGrid {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.customSmallGrid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.source {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  cursor: pointer;
  width: 100%;
}

.sourceContentWrapDark,
.sourceLocalContentWrap,
.sourceContentWrap {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.375rem;
  width: 100%;
  box-shadow: 0 0 0 1px transparent;
  /* ring-borderMain */
  transition-property: background-color;
  transition-duration: 300ms;
  background-color: rgba(127, 134, 142, 0.1);

  /* bg-offset */
  &:hover {
    background-color: rgba(127, 134, 142, 0.2);
    /* bg-offset */
  }
}

.sourceLocalContentWrap {
  background-color: rgba(0, 208, 93, 0.1);

  /* bg-offset */
  &:hover {
    background-color: rgba(0, 208, 93, 0.2);
    /* bg-offset */
  }
}

.sourceContentWrapDark {
  background-color: rgba(255, 255, 255, 0.1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.sourceTitle {
  font-size: 12px;
  color: var(--theme-color-9);
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 16px;
}

.sourceTitleTooltip {
  font-size: 12px;
  color: var(--theme-color-9);
  font-weight: 500;
  line-height: 16px;
}

.sourceUrl {
  font-size: 12px;
  font-style: normal;
  display: -webkit-box;
  font-weight: 400;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--theme-color-2);
}

.relatedInfo {
  padding: 8px 0;
  width: 100%;
  font-size: 16px;
  color: var(--theme-color-9);
  border-top: 1px solid var(--theme-border-5);
  cursor: pointer;

  &:hover {
    border-color: var(--theme-border-5);
    color: #007890;
  }

  &:last-child {
    padding: 8px 0 0;
  }
}

.iconWrap {
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px;
}

.iconTab {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.iconOnly,
.iconSync,
.iconCopy {
  cursor: pointer;

  &:hover {
    color: var(--theme-color-main);
  }
}

.iconOnly {
  padding: 9px 10px;
}

.iconContent {
  background-color: transparent;

  &:hover {
    border-radius: 16px;
    background-color: rgba(127, 134, 142, 0.2);
  }

  .iconContentWrap {
    width: auto;
    height: 32px;
    overflow: hidden;
    flex-wrap: nowrap;
    gap: 6px;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 9px 10px;

    &:hover {
      color: var(--theme-color-main);
    }
  }

  .showWrap {
    animation: fadein 0.5s infinite ease-in-out;
    animation-iteration-count: 1;
  }
}

@keyframes fadein {
  0% {
    display: none;
    opacity: 0;
    transform: scale(0.5);
    transform: translateX(-10%);
  }

  1% {
    display: flex;
    opacity: 0.2;
    // transform: scale(0.7);
  }

  10% {
    opacity: 0.3;
    // transform: scale(0.75);
  }

  25% {
    opacity: 0.4;
    // transform: scale(0.8);
  }

  40% {
    opacity: 0.55;
    // transform: scale(0.85);
  }

  50% {
    opacity: 0.65;
    // transform: scale(0.88);
  }

  60% {
    opacity: 0.75;
    // transform: scale(0.92);
  }

  75% {
    opacity: 0.9;
    // transform: scale(0.97);
  }

  100% {
    opacity: 1;
    display: flex;
    transform: scale(1);
    transform: translateX(0%);
  }
}

.toolWrap {
  border-radius: 8px;
  background: #fff;
  display: flex;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.stepsWrap {
  border-radius: 8px;
  background: var(--B-aibg-color-bg-3, #e6edef);
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  margin-top: 9px;
  align-self: stretch;
}

.steps {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 4px;
  padding: 5px 12px;
  gap: 12px;
  color: #4e5969;
  font-weight: 500;
  font-size: 12px;
  align-self: stretch;

  img {
    width: 16px !important;
    height: 16px !important;
  }
}

.success {
  margin-right: 8px;
}

.running {
  width: 100%;
  /* 确保图片充满父容器 */
  height: auto;
  animation: rotate 1s linear infinite;
}

.workflow_title_loading .running_right {
  margin-right: 8px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.workflow_title {
  cursor: pointer;
  font-size: 14px;
  color: var(--B-text-color-text-3, #86909c);
}

.anticon {
  font-size: 12px;
  vertical-align: middle;

  .icon {
    width: auto !important;
    height: auto !important;
  }
}

.workflow_title_loading {
  width: 100%;
  background: #fff;
  vertical-align: middle;

  img {
    width: 16px !important;
    height: 16px !important;
  }
}

table {
  border-collapse: collapse;
  /* 合并边框 */
  width: 100%;
  /* 表格宽度100% */
}

/* 设置表格中的单元格边框样式和颜色 */
th,
td {
  border: 1px solid #dadce2;
  /* 设置边框为1像素实线，颜色为#F2F3F5 */
  padding: 8px;
  /* 设置内边距 */
  text-align: left;
  /* 文本左对齐 */
}

/* 设置表头背景颜色 */
th {
  white-space: nowrap;
  background-color: #f2f3f5;
  /* 表头背景颜色为#F2F3F5 */
}

.copyCode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  color: var(--B-text-color-text-2, #c7cdd6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 233.333% */
  border-radius: 8px 8px 0px 0px;
  background: #2f2f2f;
  background: color(display-p3 0.1843 0.1843 0.1843);
}

.hasTableStyle {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 200%;
  }
}

.hint {
  font-size: 12px;
  // margin: 2px 0px;
  color: #c9cdd4;
}

.chat_history_item_loading {
  display: flex;
  width: 250px;
  margin-left: 18px;
  margin-top: 8px;
  height: 24px;

  :global(.dot-falling) {
    background-color: var(--theme-bg6);
    color: var(--theme-bg6);
    box-shadow: 9999px 0 0 0 var(--theme-bg6);
  }

  :global(.dot-falling::after) {
    background-color: var(--theme-bg6);
    color: var(--theme-bg6);
    box-shadow: 9999px 0 0 0 var(--theme-bg6);
  }
}

.share {
  :global(.ant-modal-content) {
    padding: 32px 64px;
  }

  .shareContent {
    gap: 32px;
    display: flex;
    flex-direction: column;

    .shareTitle {
      color: #1b212b;
      font-family: "PingFang SC";
      font-size: 23px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
    }

    .shareUrl {
      border-radius: 4px;
      border: 1px solid #f2f3f5;
      background: #f7f8fa;
      padding: 16px 18px;
      color: #80868d;
    }

    .shareBtn {
      width: 300px;
      height: 54px;
      align-self: center;
      color: #fff;
      border-radius: 4px;
      box-shadow: none;
      background: #007890 !important;
    }
  }
}

.downloadIcon {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  &:hover {
    background: var(--B-bg-color-bg-4, rgba(27, 33, 43, 0.1));
  }
}

.search_engine_wrap {
  width: 100%;
}

.search_engine_title {
  color: var(--B-text-color-text-4, #475a6d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}

.inputs_data {
  margin-top: 10px;
  margin-bottom: 18px;
  display: flex;
  width: fit-content;
  border-radius: 4px;
  background: var(--B-bg-color-bg-4, rgba(27, 33, 43, 0.1));
  padding: 2px 9px;
  color: #272b30;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.outputs_data {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
  width: 100%;
}

.search_engine_result {
  color: var(--B-text-color-text-5, #1b212b);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:hover {
    color: var(--B-text-color-text-6, #007890);
  }
}

.search_engine_result_wrap {
  display: flex;
  gap: 10px;
  align-items: center;
}

.search_engine_result_icon {
  width: 14px !important;
  height: 14px !important;
  border-radius: 4px;
}

.think {

  p,
  ul,
  li {
    font-size: 14px;
    color: var(--theme-color-9);
    font-weight: 300;
    margin-bottom: 8px;
    width: fit-content;
  }
}

.thinkTitle {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 12px;
  color: var(--theme-color-9);
  font-weight: 500;
  margin-bottom: 8px;
  width: fit-content;

  .icon {
    width: 16px !important;
    height: 16px !important;
    margin-left: 4px;
    transition: transform 0.3s ease-in-out;
  }

  .up {
    transform: rotate(270deg);
  }

  .down {
    transform: rotate(90deg);

  }
}
.ai_list_page {
  background: #f8f9fb;
  .header_box {
    padding-top: 8px;
    display: flex;
    padding-right: 24px;
    .header_box_right {
      height: 149px;
      width: 729px;
      background: #364d79;
      border-radius: 10px;
      cursor: pointer;
    }
    .content {
      color: var(--theme-bg);
      max-width: 100%; /* 图像容器的最大宽度 */
      height: 149px;
      img {
        border-radius: 10px;
        width: 100%;
        // width: auto; /* 图像宽度自适应 */
        height: auto; /* 图像高度自适应 */
        max-width: 100%; /* 图像的最大宽度 */
        height: 100%;
      }
    }
  }
  .search {
    padding: 24px;
    width: calc(100% - 729px);
    min-width: 450px;
    .chat_insert {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      flex-direction: column;
      margin-bottom: 4px;

      .chat_input_box_wrap {
        width: 100%;
      }

      .chat_input_box_wrap .chat_insert_bgc {
        background: var(--theme-bg);
        width: 100%;
      }

      .chat_input_wrap_active,
      .chat_input_wrap {
        border-radius: 4px;
        width: 100%;
        overflow: hidden;
        border: 1px solid transparent;
        background: var(--theme-bg);
        padding: 4px 14px;
        display: flex;
        border: 1px solid var(--theme-bg6);
      }

      .chat_input_wrap_active {
        border: 1px solid var(--theme-bg6);
      }

      .chat_insert_right {
        position: absolute;
        display: flex;
        cursor: pointer;
        width: 80px;
        height: 32px;
        right: 4px;
        border-radius: 8px;
        top: 5px;
        align-items: center;
        justify-content: center;
        background: var(--theme-bg6);
        .img {
          width: 15px;
          margin-right: 4px;
        }
        div {
          font-size: 14px;
          color: var(--theme-color-6);
        }
      }
      .chat_insert_delete {
        position: absolute;
        cursor: pointer;
        right: 10px;
        border-radius: 8px;
        top: 9px;
        img {
          width: 24px;
          height: 24px;
        }
      }

      .chat_insert_input {
        position: relative;
        background: var(--theme-bg);
        border: none;
        border-radius: 0;
        color: var(--theme-color-main);
        padding: 6px 36px 3px 0;
        font-size: 14px;

        &::-webkit-scrollbar {
          display: none;
        }

        &:focus {
          border: none;
          box-shadow: none;
          outline: none;
        }

        &:focus-within {
          box-shadow: none;
        }
      }

      .chat_insert_input::placeholder {
        color: var(--theme-color-2);
      }

      .chat_send_btn {
        margin-left: 16px;
      }
    }
  }
  .hot_boxs {
    margin-top: 24px;
    .hot_box {
      position: relative;
      width: 100%;
      margin-top: 10px;
      display: flex;
      height: 20px;
      overflow-wrap: break-word;
      flex-wrap: wrap;
      overflow: hidden;
      align-items: center;
      span {
        font-size: 14px;
        line-height: 20px;
        color: var(--theme-color-8);
        margin-top: -8px;
        display: inline-block;
      }
      .hot_list {
        padding: 2px 8px 2px 8px;
        border-radius: 4px;
        background: var(--theme-color-6);
        font-size: 14px;
        color: var(--theme-color-main);
        margin-right: 10px;
        margin-bottom: 8px;
        cursor: pointer;
        &:hover {
          // color: var(--theme-color-2);
          background: var(--B-bg-color-bg-4, #1D21291A);
        }
      }
    }
  }
  .page_header {
    top: 0px;
    background: #f8f9fb;
    z-index: 4;
    .page_header_title {
      color: var(--theme-bg6);
      font-size: 22px;
      font-weight: 600;
      line-height: 30.8px;
      margin-left: 22px;
      margin-bottom: 20px;
      margin-top: 2px;
    }
    .sider_menu_item {
      height: 28px;
      padding: 4px 12px 4px 12px;
      gap: 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      border: 1px solid #f8f9fb;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-right: 12px;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      &.sider_menu_item_active {
        transition: all 0.25s ease-in-out;
        background: var(--B-bg-color-bg-4, #1D21291A);
        color: #1D2129;
        font-weight: 600;
        .label {
          color: #1D2129;
          font-weight: 500;
        }
        pointer-events: none;
      }
      &:hover {
        // color: var(--theme-color-2);
        background: var(--B-bg-color-bg-4, #1D21291A);
        border: 1px solid #E8EBED;
      }
    }
    .category {
      margin-left: 24px;
      width: 95%;
      display: flex;
      overflow-wrap: break-word;
      flex-wrap: wrap;
      overflow: hidden;

      .category_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        cursor: pointer;
        color: var(--theme-bg6);
        font-weight: 500;
        z-index: 5;
        white-space: nowrap;
        &:hover {
          color: var(--theme-color-main);
        }
      }
      .category_item_two {
        position: relative;
        margin-top: 4px;
        display: flex;
        height: 26px;
        cursor: pointer;
        padding: 6px 11px 6px 11px;
        border-radius: 4px;
        // background: #fff;
        border: 1px solid #DADCE2;
        margin-right: 8px;
        color: var(--theme-color-2);
        .sub_name {
          vertical-align: middle;
          font-size: 12px;
          font-weight: 500;
          text-align: center;
        }
        img {
          width: 12px;
          height: 14px;
          vertical-align: middle;
          margin-right: 4px;
        }

        &:hover {
          height: 26px;
          color: #86909C;
          background: var(--B-bg-color-bg-4, #1D21291A);
          border: 1px solid var(--B-border-color-border-1, #F2F3F5)
        }
        &.category_item_two_active {
          transition: all 0.25s ease-in-out;
          background: #f8f9fb;
          border: 1px solid var(--theme-color-10);
          color: var(--theme-color-10);
        }
      }
    }
    .page_header_empty_box{
      font-size: 14px;
      color: var(--C-placeholder, --theme-color-2);
      margin-left: 25px;
      margin-top: 16px;
    }
  }
}

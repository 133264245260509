.page {
  // height: calc(100vh - 80px);
  // overflow-y: scroll;
  // padding-top: 40px;
  height: 100%;

  .title {
    font-family: Hind;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--theme-bg6);
    top: 216px !important;
    margin-left: 24px;
  }

  :global(.ant-btn-primary) {
    box-shadow: none;
  }
}

@media screen and (max-width: 1440px) {
  .page {
    .page_list_item {
      width: calc(33% - 44px);
      margin-right: 44px;
      margin-bottom: 28px;

      &:nth-child(4n) {
        margin-right: 44px !important;
      }

      &:nth-child(3n) {
        margin-right: 44px !important;
      }
    }
  }
}

.page {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid var(--theme-border-1);

  .form_item_recommand_tag {
    display: flex;
    align-items: center;

    .form_item_title {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-right: 8px;
      color: var(--theme-color-2);
      margin-bottom: 0;
    }
  }

  .page_inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 44px 40px;

    .form {
      .title {
        margin-bottom: 16px;
      }
      .form_item {
        width: 100%;
        :global(.ant-form-item-row) {
          width: 100% !important;
        }
        :global(.ant-form-item-control-input-content) {
          display: flex !important;
          justify-content: flex-start !important;
        }
      }
    }

    .page_title {
      font-size: 24px;
    }

    .list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 32px;
      width: 100%;
      margin-top: 64px;
      padding: 0 64px;
      cursor: pointer;

      .list_item {
        padding: 16px;
        width: 100%;
        background: var(--theme-bg2);
        border-radius: 16px;
        margin-bottom: 20px;
        border: 1px solid #333333;

        .list_item_title {
          font-size: 22px;
        }

        .list_item_desc {
          font-size: 12px;
          margin-top: 6px;
        }
      }
    }

    .page_inner_bottom {
      margin-top: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .publish_btn {
        background: var(--theme-color-10);
        border: none;
        outline: none;
        width: 100%;
        height: 44px;
        border-radius: 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
        color: var(--theme-bg);
      }

      .btn_wrap {
        display: flex;
        width: 100%;
        gap: 8px;
        .publish_btn {
          width: 50%;
        }
      }

      .rollback_container {
        width: 50%;
        height: 44px;
        background-image: var(--theme-color-5);
        border-radius: 24px; /* 圆角 */
        overflow: hidden; /* 隐藏溢出内容 */
        position: relative; /* 设置定位，以便在内部元素中添加内容 */
      }
      .rollback_content {
        position: absolute;
        background-color: var(--theme-bg);
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        height: calc(100% - 2px);
        width: calc(100% - 2px);
        border-radius: 24px;
        color: var(--theme-color-main);
        line-height: 180%;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  .publish_policy {
    font-size: 12px;
    margin-top: 12px;
    .publish_policy_link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .publish_update_time {
    font-size: 12px;
    zoom: 0.9;
    margin-top: 8px;
  }

  .recommand_tag {
    display: flex;
    align-items: center;
    height: 100%;

    .recommand_tag_item {
      margin-right: 8px;
      // border: 1px solid var(--theme-border-1);
      color: var(--theme-color-2);
      padding: 0 16px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      cursor: pointer;
      background-color: var(--theme-bg2);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .form_item_title {
    margin-top: 12px;
    margin-bottom: 4px;
    color: var(--theme-color-main);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 40px;
    margin-bottom: 10px;

    &.form_item_title_inline {
      display: flex;
      justify-content: space-between;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  :global(.ant-radio-wrapper) {
    color: var(--theme-color-main);
  }

  :global(.ant-form-item-control-input-content) {
    input {
      height: 54px;
      padding: 12px 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      background: var(--theme-bg2);
      color: var(--theme-color-main);
      border: none;
      outline: none;

      &::placeholder {
        color: var(--theme-color-2);
      }

      &:focus {
        border-color: var(--theme-bg2);
        outline: none;
      }
    }
  }

  .select {
    height: 54px;
    :global(
        .ant-select-outlined.ant-select-status-error:not(
            .ant-select-customize-input
          )
          .ant-select-selector
      ) {
      background: var(--theme-bg2) !important;
    }
    :global(.ant-select-selector) {
      background: var(--theme-bg2) !important;
      color: var(--theme-color-main) !important;
      border: none !important;
      :global(.ant-select-selection-item) {
        color: var(--theme-color-main);
      }
      padding: 12px 20px !important;
    }

    :global(.ant-select-arrow) {
      background: var(--theme-bg2) !important;
      color: var(--theme-color-main) !important;
      right: 20px !important;
    }

    // :global(.ant-select-selection-item) {
    //   background-color: var(--theme-bg4);
    // }

    // :global(.ant-select-selection-item) {
    //   padding: 6px 16px;
    //   height: auto;
    //   border-radius: 12px;
    // }
  }

  .select_tag {
    padding: 0 16px;
    height: 36px;
    background-color: var(--theme-bg4);
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    .select_tag_img {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}

.tags_popup {
  display: none !important;
}

:global(.ant-layout-header) {
  padding: 0;
}
.header_box {
  position: fixed;
  z-index: 10;
  max-width: 100%;
  min-width: 1200px;
  width: 100%;
  .avatar_url {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
  .left_box_info {
    margin-left: 13px;
    height: 100%;
    div:first-child {
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      color: var(--theme-bg6);
      margin-top: 2px;
    }
    div:nth-child(2) {
      margin-top: 6px;
      font-size: 12px;
      line-height: 12px;
      color: var(--theme-color-8);
      span {
        margin-left: 4px;
        margin-right: 4px;
        width: 1.5px;
        height: 10px;
        color: var(--theme-bg13);
      }
    }
  }
  .right_box_btn {
    margin-left: 12px;
    background: #007c96;
    color: var(--theme-bg);
    font-size: 14px;
    &:hover {
      background: #007c96 !important;
      color: none !important;
    }
  }
  .right_box_btn_left {
    font-size: 14px;
    display: flex;
    color: var(--theme-bg6);
    img {
      vertical-align: middle;
      width: 20px;
      height: 20px;
      margin-top: 1px;
      margin-right: 4px;
    }
    &:hover {
      color: #000 !important;
      border: 1px solid #d9d9d9 !important;
    }
  }
  .right_box_btn_left_ac {
    color: #15b097;
  }
  .right_box_btn_left_ac_btn {
    border: 1px solid #15b097;
    &:hover {
      border: 1px solid #15b097 !important;
      color: none !important;
    }
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  max-width: 100%;
  min-width: 1200px;
  // overflow: hidden;
  border-bottom: 1px solid var(--theme-border-1);
  background-color: var(--header-bg);
  backdrop-filter: blur(14px);
  transition: color 0.6s ease-in-out, background-color 0.6s ease-in-out;
  padding: 0 24px;
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // z-index: 9999;
  width: 100%;

  .menu {
    flex: 1;
    min-width: 0;
    justify-content: center;
    // background-color: rgba(16, 16, 15, 0.5);
    color: var(--theme-color-main);
    transition: color 0.6s ease-in-out, background-color 0.6s ease-in-out;
  }

  .lang_box {
    width: 120px;
    margin-left: 24px;

    .lang_select {
      width: 100px;
    }
  }

  .left_box {
    display: flex;

    .logo_box {
      display: flex;
      align-items: center;
      color: var(--theme-color-main);
      font-weight: 600;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 66px;
      cursor: pointer;
      padding-left: 55px;

      .logo {
        // width: 30px;
        height: 28px;
      }

      .title {
        font-size: 28px;
      }
    }
  }

  .addWechat{
    position: relative;
    height: 34px;
    padding: 0 12px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #F2F3F5;
    color: #1D2129;
    font-size: 14px;
    font-weight: 400;
    gap: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 24px;
    &.hasLogin{
      margin-right: 12px;
    }
    &:hover{
      background: #e5e5e5
    }
    .wechatQRCode{
      width: 24px;
      height: 24px;
    }
    .QRCodeContent{
      position: absolute;
      z-index: 10;
      right: 0;
      top: 34px;
      padding: 8px 12px;
      box-sizing: border-box;
      gap: 8px;
      border-radius: 8px;
      display: flex;
      font-family: PingFang TC;
      font-size: 13px;
      font-weight: 400;
      flex-direction: column;
      align-items: center;
      box-shadow: 0px 8px 8px -4px rgba(29, 33, 41, 0.035), 0px 20px 24px -4px rgba(29, 33, 41, 0.078);
      border: 1px solid #F2F3F5;
      background-color: #fff;
      line-height: 20px;
    }
  }

  .right_box {
    display: flex;
    align-items: center;

    .user_icon,
    .right_icon {
      margin-right: 16px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      &:last-child {
        margin-right: 0;
      }
    }

    .user_icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }
}

.language_popover {
  // background-color: var(--theme-bg) !important;
  width: 100%;
  height: 100%;
}

.language_popover_inside {
  width: 104px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .language_popover_inside_item {
    display: flex;
    justify-content: center;
    height: 34px;
    align-items: center;
    width: 84px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--theme-color-2);
    border-radius: 12px;
    margin-bottom: 4px;

    &.language_popover_inside_item_active {
      background: var(--theme-bg);
      color: var(--theme-color-main);
      font-weight: 500;
    }

    &:first-child {
      margin-top: 16px;
    }

    &:last-child {
      margin-bottom: 12px;
    }

    &:hover {
      background: var(--theme-bg);
      color: var(--theme-color-main);
      font-weight: 500;
    }
  }
}

.userWrap {
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background: var(--theme-bg);
  border-radius: 12px;
  box-shadow: var(--theme-logout-shadow);
}

.logout {
  color: var(--theme-color-9);
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 12px;
  width: 184px;
  height: 40px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  z-index: 2;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  &:hover {
    display: flex;
    background-color: var(--theme-bg2);
  }

  .img {
    margin-right: 14px;
    font-size: 18.5px;
  }

  &:hover {
    color: var(--theme-color-main);
  }
}

.login {
  color: var(--theme-color-2);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.userInfoWrap {
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 16px 16px 8px;
  box-sizing: border-box;

  .companyIcon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  .nameWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .userName {
    width: 120px;
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-color-9);
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis;
    white-space: nowrap; //溢出不换行
    -webkit-line-clamp: 1;
  }

  .companyName {
    width: 120px;
    font-size: 14px;
    color: var(--theme-color-2);
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis;
    white-space: nowrap; //溢出不换行
    -webkit-line-clamp: 1;
  }
}

.myCollection {
  color: #272b30;
  display: flex;
  gap: 8px;
  justify-self: center;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 8px 12px;
  &:hover {
    border-radius: 12px;
    background: var(--theme-bg2);
  }
}

@media screen and (max-width: 450px) {
  .header_box {
    min-width: 100vw;
  }

  .header {
    min-width: 100vw;
  }
}

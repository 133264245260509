.homePage {
  display: flex;
  min-height: calc(100vh - 64px);
  width: 100%;
  min-width: 850px;
  position: relative;
}

.chatTitle {
  font-size: 52px;
  color: "#252B32";
  font-weight: 700;
  white-space: "nowrap";
}

.topicList {
  z-index: 2;
  height: calc(100% - 64px);
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  background-color: #fff;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  border: 1px solid #eee;
  border-bottom: none;
  transition: width 0.5s ease-in-out, padding 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  align-self: flex-start;
}

.topicListHeader {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 25%;
  padding: 16px 16px;
  font-size: 16px;
  color: #252b32;
  font-weight: 500;
  justify-content: flex-end;
}

.topicListContent {
  height: calc(100% - 68px);
  min-height: calc(100% - 68px);
  max-height: calc(100% - 68px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  padding: 0 16px 16px;
  overflow: scroll;
}

.topicItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 12px;
  align-items: flex-start;
  gap: 6px;
  background-color: #f7f7f7;
  color: #252b32;
  border-radius: 8px;
  cursor: pointer;
}

.chatWrap {
  height: 100%;
  max-height: calc(100vh - 64px);
  width: 100%;
  overflow-y: scroll;
}

.chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 80px 8px 0 80px; /* 默认情况下的 padding */
  height: calc(100% - 80px);
  gap: 24px;
  min-height: calc(100vh - 80px);
  // background: var(--theme-bg9);

  .empty_home_page_question_change {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      margin-left: 4px;
    }
  }
}

.chatTitleColor {
  background: linear-gradient(90deg, #007a93 0%, #00dbbd 100%);
  background: linear-gradient(
    90deg,
    color(display-p3 0 0.4706 0.5647) 0%,
    color(display-p3 0 0.8447 0.7443) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: transparent;
}

.page_recommend_wrap {
  display: flex;
  justify-content: center;
  gap: 32px;
  overflow-x: hidden;
  min-width: 835px;
  max-width: 1000px;
  margin-top: 24px;
}

.page_recommend_title {
  color: var(--theme-color-9);
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 16px;
  white-space: nowrap;
}

.page_recommend_title_more {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-color-9);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  border-radius: 24px;
  border: 1px solid var(--theme-border-1);
  padding: 4px 6px 4px 16px;
  margin-bottom: 16px;
  white-space: nowrap;
}

.page_recommend_wrap_right {
  width: 100%;
  display: flex;
}

.chat {
  .page_recommand_item {
    width: 25%;
    margin-bottom: 16px;
    margin-right: 16px;
    &:nth-child(4n) {
      margin-right: 0px;
    }
  }
}

.page_recommend_item_showTopic {
  .page_recommend_wrap_right {
    grid-template-columns: 1fr;
  }
}

.showTopic {
  width: 260px;
  min-width: 260px;
}

.hideTopic {
  width: 0px;
  border: none !important;
  min-width: 0px;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

.filesList {
  width: 100%;
  position: relative;
  top: -36px;
  max-width: 728px;
  overflow: hidden;
}

@keyframes fadeout {
  from {
    width: 360px;
  }
  to {
    width: 0 !important;
  }
}

@keyframes fadein {
  from {
    width: 0;
  }
  to {
    width: 360px;
  }
}

@media screen and (max-width: 450px) {
  .chat_input_box_wrap {
    margin-bottom: 10px;
  }
  .homePage {
    height: 100%;
    min-height: 100%;
    min-width: 100vw !important;
    justify-content: flex-start !important;
    overflow-x: auto;
    :global(.ant-segmented) {
      background: #1d21291a 10%;
      font-weight: 500;
    }
    :global(.ant-segmented .ant-segmented-item) {
      color: #86909c;
    }
    :global(.ant-segmented .ant-segmented-item-selected) {
      color: #1d2129;
    }
  }

  .chatWrap {
    height: 100%;
    max-height: 100%;
    width: 100%;
  }

  .chat {
    // padding: 80px 16px 0 !important;
    min-width: 100vw !important;
    justify-content: space-between !important;
    min-height: 100vh !important;
    padding: 110px 16px 0;
  }
  .chatTitle {
    font-size: 38px !important;
  }

  .page_recommend_wrap {
    display: none;
  }
}

.mobilleHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 66px;
}

.mobileChatInput {
  display: flex;
  flex-direction: column;
  background: #e0e6e8;
  .filesList {
    top: unset;
    overflow: auto;
  }
  .chat_insert_right {
    bottom: unset;
  }
}
.chat_insert_send {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.pageChoser {
  position: absolute;
  top: 26px;
  z-index: 2;
}
.historyPage {
  position: absolute;
  top: 0;
  left: 0;
  padding: 76px 20px 50px 20px;
  // height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.historyDateLabel {
  overflow: hidden;
  color: #81909e;
  text-overflow: ellipsis;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.page_recommend_wrap_card {
  margin-top: 12px;
  margin-bottom: 44px;
}

.mobileChatCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 12px;
  align-items: flex-start;
  gap: 6px;
  border-radius: 4px;
  background: #f7f8fa;
  color: #252b32;
  cursor: pointer;
  transition: background-color 0.3s;
  &:active {
    background-color: rgba(27, 33, 43, 0.1) !important;
  }
}

.mobileChatCardTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
}

.empty_home_page_recommand {
  margin-top: 48px;
  display: flex;
  width: 835px;
  position: relative;
  flex-wrap: wrap;
  .questions_title {
    width: 100%;
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      color: #1d2129;
      line-height: 36px;
    }
    .questions_refresh {
      width: 95px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: auto; /* 自动将刷新按钮推到右侧 */
      padding: 5px 10px;
      border-radius: 20px;
      transition: background-color 0.3s ease;
      .img {
        margin-right: 5px;
      }
      span {
        font-size: 14px;
        color: #333;
      }
    }
  }
  .empty_home_page_recommand_item {
    background-color: #fff;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid var(--theme-border-1);
    margin-top: 12px;
    padding-left: 15px;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    color: #272b30;
    cursor: pointer;
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
    .empty_home_page_recommand_info {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
    }
    .empty_home_page_category_icon {
      display: flex;
      margin-top: auto;
      width: 100%;
      align-items: center;
      font-size: 12px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        filter: drop-shadow(var(--theme-color-9) 10000px 0);
      }
    }
    .empty_home_page_recommand_icon {
      width: 60px;
      height: 60px;
      border-radius: 8px;
      margin-right: 12px;
      object-fit: cover;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    }
  }
}

.pattern {
  margin: 0 auto;
  width: 196px;
  height: 42px;
  :local :global(.ant-segmented-item) {
    width: 50%;
  }
  :local :global(.ant-segmented-group) {
    height: 36px;
  }
  :local :global(.ant-segmented-item-label) {
    font-weight: 600;
    line-height: 36px !important;
  }
}

.dialogue {
  position: absolute;
  top: 26px;
  text-align: center;
  line-height: 28px;
  left: 0;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: "pointer";
  width: 26px;
  height: 28px;
  background: #fff;
  filter: drop-shadow(0px 2px 4px rgba(28, 33, 42, 0.06))
    drop-shadow(0px 4px 8px rgba(28, 33, 42, 0.1));
  fill: color(display-p3 1 1 1);
}
.dialogue_m {
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  box-shadow: 0px 2px 4px -2px #1d21290f;
  box-shadow: 0px 4px 8px -2px #1d21291a;
  z-index: 10;
}

.input_wrap {
  width: 100%;
  max-width: 728px;
  align-self: center;
  margin-bottom: 12px;
}

@media screen and (max-width: 450px) {
  .empty_home_page_recommand {
    width: 100%;
  }
}
.mobileQuestion {
  bottom: 140px;
  position: absolute;
  padding: 20px;
}
.chatHistory {
  position: sticky;
  width: 26px;
  height: 28px;
  top: 0;
  .chatHistorySpan {
    position: absolute;
    top: 20px;
    text-align: center;
    line-height: 28px;
    left: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    width: 26px;
    height: 28px;
    background: #fff;
    fill: color(display-p3 1 1 1);
    filter: drop-shadow(0px 2px 4px rgba(28, 33, 42, 0.06))
      drop-shadow(0px 4px 8px rgba(28, 33, 42, 0.1));
  }
}

.title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  font-size: 14px;
}

.time {
  color: "#7D868F";
  font-size: 12px;
}

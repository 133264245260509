.agent_item {
  height: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;

  .tag_list {
    display: flex;
    position: absolute;
    right: 12px;
    bottom: 40px;

    .tag_list_item {
      padding: 3px 5px;
      border: 1px solid #333;
      border-radius: 8px;
      margin-left: 12px;
    }
  }

  .agent_item_img_box {
    height: auto;
    width: 100%;
    position: relative;
    border-radius: 12px;
    .agent_item_type {
      position: absolute;
      top: 12px;
      left: 12px;
      padding: 0px 12px 0px 8px;
      background-color: var(--theme-bg7);
      height: 28px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--theme-color-7);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }

    .agent_item_delete {
      width: 28px;
      height: auto;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
    }

    .agent_item_number {
      position: absolute;
      bottom: 8px;
      left: 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: var(--theme-color-7);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;

      img {
        width: 16px;
        height: 16px;
      }
    }
    .agent_item_mask {
      width: 100%;
      height: 83px;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      border-radius: 0 0 12px 12px;
      position: absolute;
      bottom: 0;
      z-index: 2;
    }
    .agent_item_action {
      height: 28px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 8px;
      left: 12px;
      color: var(--theme-color-7);
      z-index: 3;

      .agent_item_action_item {
        display: flex;
        align-items: center;
        margin-right: 6px;

        .img {
          margin-left: 6px;
          margin-right: 4px;
        }
      }
    }
    .agent_item_img_padding {
      padding-top: 66.7%;
      height: 0;
      width: 100%;
    }
    .agent_item_img {
      border-radius: 12px;
      // height: 236px;
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .agent_item_info {
    height: 44px;
    margin-top: 16px;
    color: var(--theme-color-main);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    line-height: 140%;

    .avator {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
      margin-top: 2px;
    }

    .author {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: var(--theme-color-2);
      margin-top: 4px;
    }

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .action {
      margin-left: auto;
    }
  }
}

.agent_item {
  width: 100%;
  min-height: 187px;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--C-, #eeeeee);
  background-color: #fff;
  border-radius: 12px;
  transition: transform 0.3s ease;
  .iconfont_collect {
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .agent_item_collect {
    height: auto;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    z-index: 3;
    opacity: 0;
  }
  &:hover {
    .agent_item_collect {
      opacity: 1;
      // background: #eeeeee;
      // border-radius: 10px;
      // height: 37px;
      // width: 37px;
    }
  }
  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 12px 16px -4px rgba(21, 30, 58, 0.08),
      0px 4px 6px -2px rgba(21, 30, 58, 0.03);
    box-shadow: 0px 12px 16px -4px color(display-p3 0.0902 0.1176 0.2196 / 0.08),
      0px 4px 6px -2px color(display-p3 0.0902 0.1176 0.2196 / 0.03);
  }
  .agent_item_img_box {
    width: 100%;
    margin: 24px;
    border-radius: 12px;
    .agent_item_img {
      width: 64px;
      height: 64px;
      border-radius: 12px;
      margin-bottom: 16px;
    }
    .agent_item_box {
      width: calc(100% - 48px);
      &_title {
        font-size: 16px;
        font-weight: 600;
        color: var(--C-, #444d61);
        margin-bottom: 12px;
      }
      &_synopsis {
        max-width: 234px;
        font-size: 13px;
        color: #798397;
        min-height: 30px;
        overflow: hidden; /* 溢出隐藏 */
        display: -webkit-box; /* 使用 Flexbox 布局 */
        -webkit-box-orient: vertical; /* 垂直方向 */
        -webkit-line-clamp: 2; /* 行数限制 */
        text-overflow: ellipsis; /* 文本溢出显示省略号 */
      }
      &_clamp {
        -webkit-line-clamp: 3; /* 行数限制 */
        margin-bottom: 28px;
        min-height: 44px;
      }
      &_tags {
        max-width: 234px;
        display: flex;
        overflow-wrap: break-word;
        flex-wrap: wrap;
        overflow: hidden;
        margin-top: 12px;
        height: 26px;
      }
      &_tag {
        padding: 2px 6px 2px 6px;
        border-radius: 4px;
        font-size: 12px;
        color: #80868d;
        margin-right: 8px;
        background-color: #f7f7f7;
        margin-bottom: 8px;
      }
      &_info {
        margin-top: 4px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
        align-items: center;
        font-size: 12px;
        color: #bbc2c9;
        position: relative;
        &_right {
          position: absolute;
          right: 0;
          .img {
            width: 16px;
            height: 16px;
            vertical-align: middle;
          }
          span {
            vertical-align: middle;
          }
        }
        &_name {
          width: calc(100% - 24px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .agent_item_box_info_btn {
      padding: 4px 16px 4px 16px;
      border-radius: 4px;
      background: var(--B-brand-color-brand-1, #007890);
      color: #fff;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
.handiness {
  cursor: default;
}

.upload {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  :global(.ant-upload-wrapper) {
    width: 100% !important;
    max-width: 300px;
  }

  :global(.ant-upload-select) {
    display: flex;
    align-items: center;
    justify-self: center;

    :global(.ant-upload) {
      display: flex;
    }
  }
  //   :global(.ant-upload-select) {
  //     width: 100% !important;
  //     max-width: 300px;
  //   }

  //   img {
  //     width: unset !important;
  //     height: 100% !important;
  //   }
}

.upload_inner {
  margin-top: 24px;
  width: 382px;
  height: 196px;
  border-radius: 12px;
  border: 1px dashed var(--theme-border-4);
  background: var(--B-color-bg-2, #f7f8fa);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  position: relative;
  cursor: pointer;
  font-style: normal;
  line-height: normal;
  gap: 10px;
  color: var(--B-color-text-3, #81909e);

  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .title {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--C-, #252b32);
    font-weight: 500;
    gap: 12px;
    line-height: 22px;
  }

  .tips {
    width: 100%;
    color: var(--B-, color(display-p3 0 0.4706 0.5647));
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    word-break: break-word;
  }

  img {
    width: 52px;
    height: 52px;
    margin-bottom: 2px;
  }
}

.upload_inside {
  width: 300px;
  max-width: 300px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  border: 1px dotted gray;
  cursor: pointer;
}

.bg_img {
  width: unset !important;
  height: 150px !important;
}

.listWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 16px;
  flex-direction: column;
  border-radius: 12px;
  padding: 16px;
  border: 1px solid
    var(--C-color-border-1, color(display-p3 0.9333 0.9333 0.9333));
  background: var(--C-color-bg-2, #f7f7f7);
}

.listItem {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.listItemTitle {
  display: flex;
  align-items: center;
  color: #1a2029;
  width: 75%;
}

.listItemSuccess {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #272b30;
  gap: 6px;
}

.listItemError {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #ff4d4f;
  gap: 6px;
}

.listItemLoading {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #838a95;
  gap: 6px;

  img {
    width: 16px;
    height: 16px;
    animation: rotate 2s linear infinite; /* 使用 rotate 动画，2秒一次，线性过渡，无限循环 */
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.agent_item {
  height: 100%;
  width: 100%;
  position: relative;

  .avator {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 6px;
  }

  .tag_list {
    display: flex;
    position: absolute;
    right: 12px;
    bottom: 40px;

    .tag_list_item {
      padding: 3px 5px;
      border: 1px solid #333;
      border-radius: 8px;
      margin-left: 12px;
    }
  }

  .iconfont_collect {
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .agent_item_img_box {
    height: auto;
    width: 100%;
    position: relative;
    border-radius: 12px;
    border: 1px solid var(--theme-border-1);

    .agent_item_delete {
      width: 28px;
      height: auto;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      z-index: 3;
    }

    .agent_item_action {
      height: 28px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 8px;
      left: 10px;
      color: var(--theme-color-7);
      z-index: 3;

      .agent_item_action_item {
        display: flex;
        align-items: center;
        margin-right: 6px;

        .img {
          margin-left: 6px;
          margin-right: 4px;
        }
      }
    }

    .agent_item_type {
      position: absolute;
      top: 12px;
      left: 12px;
      padding: 0px 12px 0px 8px;
      background-color: var(--theme-bg7);
      height: 28px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--theme-color-7);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }

    .agent_item_mask {
      width: 100%;
      height: 65px;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
      position: absolute;
      bottom: 0;
      z-index: 2;
      border-radius: 0 0 12px 12px;
    }

    .agent_item_number {
      position: absolute;
      bottom: 8px;
      left: 12px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: var(--theme-color-7);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .agent_item_img_padding {
      padding-top: 133.33%;
      height: 0;
      width: 100%;
    }

    .agent_item_img {
      border-radius: 12px;
      // height: 236px;
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    // .agent_detail {
    //   width: 372px;
    //   min-height: 520px;
    //   padding-bottom: 24px;
    //   height: auto;
    //   border-radius: 12px;
    //   background-color: var(--theme-bg);
    //   position: absolute;
    //   z-index: 5;
    //   // left: -0%;
    //   top: -50%;
    //   overflow: hidden;
    //   display: none;
    //   flex-direction: column;
    //   filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
    //   z-index: 9999;

    //   .agent_item_content {
    //     opacity: 0;
    //     transition: opacity 0.3s ease-in-out;
    //   }

    //   .agent_detail_img_box {
    //     width: 100%;
    //     padding-top: 56.25%;
    //     position: relative;

    //     .agent_item_padding {
    //       width: 100%;
    //       height: 0;
    //       padding-top: 56.25%;
    //     }
    //     .agent_detail_img {
    //       height: 100%;
    //       width: 100%;
    //       position: absolute;
    //       top: 0;
    //       left: 0;
    //       object-fit: cover;
    //     }

    //     .agent_detail_img_mask {
    //       height: 100%;
    //       width: 100%;
    //       position: absolute;
    //       top: 0;
    //       left: 0;
    //       background: linear-gradient(
    //         180deg,
    //         rgba(16, 16, 15, 0) 0.15%,
    //         #10100f 99.99%
    //       );
    //       color: var(--theme-color-main);
    //       font-size: 14px;
    //       font-style: normal;
    //       font-weight: 400;
    //       display: flex;
    //       align-items: flex-start;
    //       line-height: 140%;

    //       .user_info_box {
    //         position: absolute;
    //         bottom: 24px;
    //         left: 24px;
    //         display: flex;
    //         align-items: flex-start;

    //         .name {
    //           overflow: hidden;
    //           text-overflow: ellipsis;
    //           display: -webkit-box;
    //           -webkit-line-clamp: 4;
    //           -webkit-box-orient: vertical;
    //           color: var(--theme-color-7);
    //         }

    //         .author {
    //           font-size: 12px;
    //           font-style: normal;
    //           font-weight: 400;
    //           color: var(--theme-color-2);
    //           margin-top: 4px;
    //         }

    //         .action {
    //           margin-left: auto;
    //         }
    //       }
    //     }
    //   }

    //   .agent_detail_info {
    //     padding: 0 23px;
    //     flex: 1;
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-between;

    //     .agent_detail_intro {
    //       color: var(--theme-color-main);
    //       margin-top: 3px;
    //       display: -webkit-box;
    //       -webkit-line-clamp: 3;
    //       -webkit-box-orient: vertical;
    //       overflow: hidden;
    //       margin-top: 18px;
    //     }

    //     .agent_detail_tool {
    //       margin-top: 24px;

    //       .agent_detail_tool_list {
    //         margin-top: 8px;
    //         display: flex;
    //         flex-wrap: wrap;

    //         .agent_detail_tool_item {
    //           margin-right: 8px;
    //           border-radius: 24px;
    //           background: var(--theme-bg8);
    //           padding: 8px 16px;
    //           margin-bottom: 12px;
    //           color: var(--theme-color-main);
    //         }
    //       }
    //     }

    //     .agent_detail_skill {
    //       margin-top: 12px;

    //       .agent_detail_skill_list {
    //         margin-top: 8px;
    //         display: flex;
    //         flex-wrap: wrap;

    //         .agent_detail_skill_item {
    //           margin-right: 8px;
    //           border-radius: 24px;
    //           background: var(--theme-bg8);
    //           padding: 8px 16px;
    //           margin-bottom: 12px;
    //           color: var(--theme-color-main);
    //         }
    //       }
    //     }

    //     .agent_detail_date {
    //       font-size: 12px;
    //       font-style: normal;
    //       font-weight: 400;
    //       line-height: 20px;
    //       margin-top: 12px;
    //     }

    //     .btn_box {
    //       margin-top: 24px;
    //       display: flex;
    //       position: relative;
    //       width: 100%;
    //       height: 40px;

    //       .btn_to_chat {
    //         position: absolute;
    //         left: 0;
    //         color: var(--theme-color-3);
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         width: 168px;
    //         height: 40px;
    //         .btn_text {
    //           margin-left: -4px;
    //         }

    //         img {
    //           width: 100%;
    //           height: 100%;
    //         }
    //       }

    //       .btn_to_create {
    //         position: absolute;
    //         right: 0;
    //         color: var(--theme-color-main);
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         justify-content: center;
    //         width: 168px;
    //         height: 40px;

    //         .btn_text {
    //           margin-left: 3px;
    //         }

    //         img {
    //           width: 100%;
    //           height: 100%;
    //         }
    //       }

    //       .btn_text {
    //         position: absolute;
    //         width: 100%;
    //         height: 100%;
    //         left: 0;
    //         top: 0;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         font-size: 16px;
    //         font-style: normal;
    //         font-weight: 500;
    //       }
    //     }
    //   }
    // }

    &:hover {
      .agent_detail {
        display: flex;
        animation: fadein 0.17s infinite ease-in-out;
        animation-iteration-count: 1;

        .agent_item_content {
          opacity: 1;
        }
      }
    }
  }

  .agent_item_collect {
    height: auto;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    z-index: 3;
    opacity: 0;
  }

  &:hover {
    .agent_item_collect {
      opacity: 1;
    }
  }

  .agent_item_info {
    height: 66px;
    margin-top: 12px;
    color: var(--theme-color-main);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: flex-start;
    padding: 0 12px;
    width: 100%;
    flex-direction: column;

    .user_info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: var(--theme-color-2);

      .author {
        display: flex;
        align-items: center;

        span {
          width: 80px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
      }

      .like {
        display: flex;
        align-items: center;

        .img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      height: 40px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 8px;
    }

    .action {
      margin-left: auto;
    }
  }
}
.page {
  width: 100%;
  display: flex;
  justify-content: center;
  border-right: 1px solid var(--theme-border-1);

  .add_btn {
    background: var(--theme-color-10);
    border: none;
    outline: none;
    width: 100;
    height: 30px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--theme-color-6);
  }

  .upload_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .create_context {
    margin-top: 40px;
    width: 100%;
  }

  .create_tab {
    min-height: 64px;
    height: 64px;
    width: 428px;
    border-radius: 24px;
    display: flex;
    margin-top: 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    background-color: var(--theme-bg11);
    position: relative;

    .create_item {
      height: 64px;
      width: 219px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 2;
      transition: all 0.35s ease-in-out;

      .question {
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }

      &.create_item_active {
        color: var(--theme-color-main);
      }
    }

    .create_tab_flow {
      transition: all 0.35s ease-in-out;
      position: absolute;
      width: 212px;
      height: 56px;
      border-radius: 22px;
      background-color: var(--theme-bg4);

      &.create_tab_flow_left {
        left: 4px;
        top: 4px;
      }

      &.create_tab_flow_right {
        left: 212px;
        top: 4px;
      }
    }
  }

  .card_create_setting,
  .create_setting {
    width: 100%;
    height: 100%;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .flexContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .form_item_title {
      margin-top: 12px;
      margin-bottom: 4px;
      color: var(--theme-color-main);
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-top: 40px;
      margin-bottom: 10px;

      .form_item_actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 12px;
        margin-bottom: 12px;

        .actions_link {
          width: 115px;
          height: 31px;
          border-color: var(--theme-color-10);
          border-width: 1px;
          border-style: solid;
          border-radius: 8px;
          margin-left: 12px;
          cursor: pointer;
          color: var(--theme-color-10);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .number {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        color: var(--theme-color-10);
        margin-left: 4px;
        width: 22px;
      }

      &.form_item_title_inline {
        display: flex;
        justify-content: space-between;

        div {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          white-space: nowrap;
        }
      }

      &:first-child {
        margin-top: 0;
      }
    }

    .form_item {
      .input {
        width: 100%;
      }

      // .select {
      //   width: 100%;
      // }
    }

    .form_group {
      border: 1px dashed var(--theme-border-1);
      border-radius: 12px;

      &:not(:first-child) {
        margin-top: 20px;
      }

      .form_group_item {
        .slider {
          width: 367px;
        }

        .form_item_title_inline {
          justify-content: flex-start;
          align-items: center;

          .select {
            height: 34px !important;
            width: auto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin-left: 20px;
          }
        }
      }

      .form_group_item {
        padding: 0 20px;
        .form_item_title {
          margin-top: 0px;
        }

        padding-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px dashed var(--theme-border-1);

        &:last-child {
          border-bottom: unset;
        }
      }

      :global(.ant-form-item) {
        margin-bottom: 0;
      }
    }

    .create_setting_inner {
      border: none;
    }

    .knowledge_base_list {
      color: var(--theme-color-2);

      .knowledge_base_list_item {
        margin-top: 12px;
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
        span {
          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    // ::-webkit-scrollbar {
    //   /*整体样式*/
    //   width: 10px;
    //   height: 9px;
    // }
    // ::-webkit-scrollbar-track {
    //   /*轨道*/
    //   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    //   /* background: #ededed; */
    //   background: #333;
    //   border-radius: 10px;
    // }
    // ::-webkit-scrollbar-thumb {
    //   /*滚动条小方块*/
    //   border-radius: 10px;
    //   /* background-color: #2e75d3; */
    //   background-color: #888;
    //   background-image: -webkit-linear-gradient(
    //     45deg,
    //     rgba(255, 255, 255, 0.2) 25%,
    //     transparent 25%,
    //     transparent 50%,
    //     rgba(255, 255, 255, 0.2) 50%,
    //     rgba(255, 255, 255, 0.2) 75%,
    //     transparent 75%,
    //     transparent
    //   );
    // }

    .sendor {
      min-height: 350px;
    }

    .skill {
      margin-bottom: 24px;
      .skill_title {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        color: var(--theme-color-main);
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      &.skills_nodata {
        :global(.ant-card-body) {
          padding: 0;
        }
      }
    }

    .temperature {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      margin-left: auto;
      padding-left: 32px;
      white-space: nowrap;
      padding-right: 6px;
      .slider_temperature {
        flex: 1;
        min-width: 160px;
        max-width: 250px;
        margin-left: 12px;
      }
    }
  }

  .card_create_setting {
    margin-top: 24px;
    .create_setting_inner {
      width: 100%;
      border: none;
    }
  }

  .slider {
    margin: 0 10px;
    :global(.ant-slider-handle::before) {
      display: none;
    }
    :global(.ant-slider-handle::after) {
      width: 12px;
      height: 12px;
      background-image: var(--theme-color-5);
      box-shadow: none;
      filter: drop-shadow(0px 0px 4px #03fff0);
    }
    :global(.ant-switch-checked) {
      :global(.ant-slider-handle::after) {
        background-image: #a7a7a7;
      }
    }

    :global(.ant-slider-rail) {
      background-color: var(--theme-bg2);
      height: 6px;
    }
    :global(.ant-slider-track) {
      background-color: var(--theme-bg10);
      height: 6px;
    }
  }

  :global(.ant-form-item-control-input-content) {
    input {
      height: 54px;
      padding: 12px 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      background: var(--theme-bg2);
      color: var(--theme-color-main);
      border: none;
      outline: none;

      &::placeholder {
        color: var(--theme-color-2);
      }

      &:focus {
        border-color: var(--theme-bg2);
        outline: none;
      }
    }
  }

  .model_select {
    // padding: 6px 16px;
    height: 34px !important;
    width: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-left: 20px;
  }

  .textarea {
    background: var(--theme-bg2);
    color: var(--theme-color-main) !important;
    border: none;
    outline: none;
  }

  .select {
    height: 54px;
    :global(.ant-select-selector) {
      background: var(--theme-bg2) !important;
      color: var(--theme-color-main) !important;
      border: none !important;
      :global(.ant-select-selection-item) {
        color: var(--theme-color-main);
      }
      padding: 12px 20px !important;
    }

    :global(.ant-select-arrow) {
      background: var(--theme-bg2) !important;
      color: var(--theme-color-main) !important;
      right: 20px !important;
    }
  }

  .switch {
    width: 54px;
    height: 32px;
    overflow: hidden;

    :global(.ant-switch-handle::before) {
      background-image: unset;
      background-color: #a7a7a7;
      border-radius: 50%;
      filter: unset;
    }

    &:global(.ant-switch-checked) {
      :global(.ant-switch-handle::before) {
        background-image: var(--theme-color-5);
        border-radius: 50%;
        filter: drop-shadow(0px 0px 8px rgba(199, 255, 3, 0.8));
      }
    }
  }

  :global(.ant-switch) {
    :global(.ant-switch-handle) {
      width: 24px;
      height: 24px;
      top: 4px;
      inset-inline-start: 4px;
    }
  }

  :global(.ant-switch-checked) {
    :global(.ant-switch-handle) {
      top: 4px;
      inset-inline-start: calc(100% - 28px);
    }
  }

  .card {
    background-color: var(--theme-bg2);
    color: var(--theme-color-main) !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    border-radius: 12px;
    border: none;

    :global(.ant-card-head) {
      height: 54px;
      padding: 0 20px;
    }

    :global(.ant-card-body) {
      border-top: 1px solid var(--theme-border-1);
      padding: 12px 20px;
    }
  }

  .card_nodata {
    :global(.ant-card-head) {
      border-bottom: none;
    }
    :global(.ant-card-body) {
      border-top: none;
    }
  }

  :global(.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled)) {
    background-color: var(--theme-bg2) !important;
    color: var(--theme-color-main) !important;
    border-radius: 12px;
  }
}

:global(.ant-select-dropdown) {
  background-color: var(--theme-bg2);
  color: var(--theme-color-main) !important;

  :global(.ant-select-item) {
    background-color: var(--theme-bg2);
    color: var(--theme-color-main) !important;
    border-radius: 12px;
  }

  :global(
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled)
    ) {
    background-color: var(--theme-bg1);
    color: var(--theme-color-main) !important;
    border-radius: 12px;
    font-weight: 400;
  }

  :global(.ant-select-item-option-selected) {
    background-color: var(--theme-bg1);
    color: var(--theme-color-main) !important;
    border-radius: 12px;
    font-weight: 400;
  }

  :global(.ant-select-option-active) {
    background-color: var(--theme-bg1);
    color: var(--theme-color-main) !important;
    border-radius: 12px;
    font-weight: 400;
  }

  :global(.ant-select-item:hover) {
    background: var(--theme-bg1);
  }
}

.spin {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected_file {
  position: absolute;
  margin-left: 35px;
  margin-top: 16px;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  color: var(--dot-color);
  .span {
    color: var(--theme-bg10) !important;
  }
}

.modal {
  :global(.ant-btn-default) {
    color: var(--dot-color) !important;
    border: 1px solid var(--theme-border-1) !important;
    font-weight: 500;
    font-size: 14px;
    box-shadow: none !important;
    background: transparent !important;
  }
  :global(.ant-btn-primary) {
    background-color: var(--theme-color-10) !important;
    border: none !important;
    font-weight: 500;
    font-size: 14px;
    color: var(--theme-bg) !important;
    box-shadow: none !important;
  }
}

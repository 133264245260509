$item-height: 44px;
$gap: 20px;

.modal {
  overflow: hidden;
  padding: 4px !important;
  :global(.ant-modal-content) {
    border-radius: 20px;
    background: var(--theme-bg);
    color: var(--theme-color-2);
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.25);
  }

  :global(.ant-modal-header) {
    background: var(--theme-bg);
    color: var(--theme-color-main);
  }

  :global(.ant-modal-close-x) {
    color: var(--theme-color-main);
  }
  :global(.ant-modal-title) {
    color: var(--theme-color-main);
  }
  .content {
    width: 100%;
    display: flex;
    color: var(--theme-color-main);
    flex-direction: column;
    .title {
      color: var(--theme-color-8);
      font-family: PingFang SC;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      margin-top: 8px;
    }
    .tip {
      font-size: 14px;
      line-height: 24px;
      color: #1e3239;
      font-weight: 600;
      margin-top: 28px;
      margin-bottom: 8px;
      text-align: left;
    }
    .form {
      display: flex;
      margin-bottom: 40px;
      .form_item {
        margin-right: 8px;
        display: inline-block;
        width: 235px;
      }
    }
  }
  .footer {
    position: relative;
    width: 270px;
    float: right;
    margin-top: 10px;
    .footer_btn_cancel{
      right: 80px;
      position: absolute;
    }
    .footer_btn {
      position: absolute;
      right: 0px;
      background: var(--theme-color-10);
      color: var(--theme-bg);
      font-family: PingFang TC;
      font-size: 14px;
      margin-left: 8px;
      :hover {
        color: var(--tooltip-bg-color);
      }
    }
  }
}

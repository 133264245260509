.page,  .mobileChatPage {
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px 12px 30px 0px rgba(208, 204, 217, 0.1);
  background: #e0e6e8;
  width: 100%;
  height: fit-content;
  padding: 30px 16px 16px;
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 4px));
  gap: 8px;
  max-height: 240px;
  overflow-y: auto;
  justify-content: center;
}

.cardWrap {
  width: 100%;
  background: #fff;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 12px;
  color: #18202a;
  position: relative;
  line-height: 20px;
}

.cardDetail {
  display: flex;
  flex-direction: column;
  width: calc(100% - 56px);
}

.cardTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cardType {
  line-height: 20px;
  color: #818a96;
}

.close {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
  padding: 4px;
}

.icon {
  width: 16px;
  height: 16px;
  animation: rotate 2s linear infinite; /* 使用 rotate 动画，2秒一次，线性过渡，无限循环 */
}

.uploading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  color: #86909c;
}

.img {
  width: 36px !important;
  height: 36px !important;
}

.listItemError {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #ff4d4f;
  gap: 6px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 450px){
  .mobileChatPage {
    border-radius: 0 0 8px 8px;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
  }
  .fileOfmobileChat {
    width: 100% !important;
    .cardWrap {
      max-width: unset;
    }
  }
  .page {
    // grid-template-columns: repeat(1, 100%);
    border-radius: 0 0 8px 8px;
    display: flex;
    justify-content: flex-start;
    background: unset;
  }
  // .cardWrap {
    // max-width: 75%;
  // }
}
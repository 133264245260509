.modal {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 500px;

    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 14px;
      margin-top: 32px;
      text-align: center;
    }

    .selector {
      margin-top: 32px;

      .selector_item {
        margin-top: 16px;
        padding: 16px;
        width: 300px;
        height: 30px;
        border: 1px solid rgba(45, 92, 109, 1);
        border-radius: 10px;
        color: var(--theme-color-main);
        cursor: pointer;
      }
    }

    .to_detail {
      margin-top: 40px;
    }
  }
}

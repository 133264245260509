body {
  min-height: 100%;
  height: 100%;
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.layout {
  background-color: var(--theme-bg3) !important;
}

.content {
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  min-height: calc(100vh - 64px);
  min-width: 952px;
  background: var(--theme-bg);

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

@media screen and (max-width: 450px) {
  .content {
    min-height: 100vh;
  }
}

.react-loading-skeleton {
  --base-color: var(--theme-bg-skeleton-2);
  --highlight-color: var(--theme-bg-skeleton);
}

.loader {
  display: block;
  position: relative;
  height: 24px;
  width: 250px;
  box-sizing: border-box;
  overflow: hidden;
}
.loader:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 12px;
  height: 12px;
  animation: ballbns 1.2s ease-in-out infinite;
}

@keyframes ballbns {
  0% {
    left: 0;
    transform: translateX(-100%) rotate(0deg);
    border-radius: 0;
    background: var(--dot-color);
  }
  100% {
    left: 100%;
    transform: translateX(0%) rotate(360deg);
    border-radius: 50%;
    background: var(--dot-color);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 450px) {
  .content {
    min-width: 100vw;
    overflow-x: auto;
  }
}

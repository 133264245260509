.card {
  width: 260px;
  height: 120px;
  margin: 10px;
  background-color: var(--theme-bg2);
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid transparent;

  .checked {
    border-radius: 12px;
    border: 1px solid var(--theme-color-10);
  }

  .card_content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;

    .checkbox {
      position: absolute;
      width: 16px;
      top: 13px;
      right: 10px;
      :global(.ant-checkbox-checked .ant-checkbox-inner) {
        background-color: var(--theme-color-10) !important;
      }
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;

      img {
        width: 80px;
        height: 80px;
      }
    }

    .detail_content {
      width: 115px;
      height: 100px;
      position: relative;
      margin: 10px;

      .text {
        color: var(--theme-bg6);
        font-weight: 400;
        line-height: 19.6px;
        font-size: 14px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .footer {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        display: flex;
        align-items: flex-end;

        .status {
          color: var(--theme-bg6);
          font-size: 12px;
          .status_parsing {
            color: #ffd700;
          }

          .status_ready {
            color: #32cd32;
          }

          .status_failed {
            color: red;
          }
        }

        .delete {
          width: 28px;
          height: auto;
          position: relative;
          right: 10px;
          bottom: 0px;

          .delete_button {
            border: none;
            border-radius: 15px;
            background-color: var(--theme-bg6);
            width: 48px;
            height: 28px;
            color: var(--theme-bg);
          }

          .delete_button:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.page {
  height: 100%;
  width: 100%;

  .page_list_item_add {
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid #333;
    width: calc(30% - 30px);
    border-radius: 12px;
    cursor: pointer;
    color: var(--theme-color-main);
    margin-left: 24px;
    margin-top: 20px;
    border-width: 0;

    .page_list_item_add_inner {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      background-color: var(--theme-bg2);
      gap: 8px;
      border-radius: 12px;

      img {
        width: 24px;
        height: 24px;
      }

      span {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }

    div {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .title {
    font-family: Hind;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: var(--theme-bg6);
    top: 216px !important;
    margin-left: 24px;
  }

  .folder_container {
    width: 100%;
    margin: 0 24px 10px 24px;
    border-radius: 12px;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 20px;

    .folderItem {
      transition: transform 0.3s ease;
      padding: 16px;
      border-radius: 12px;
      border: var(--Radius-Sharp, 1px) solid #DEE7E9;
      background: #FCFDFD;
      box-shadow: 0px 7px 13.4px -2px rgba(21, 30, 58, 0.02);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & :hover {
        cursor: pointer;
      }

      .content {
        display: flex;
        margin-bottom: 16px;
      }

      .image {
        width: 64px;
        height: 64px;
        flex: 0 0 64px;
      }

      .text {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #0A343B;
        flex: 1 0;
        min-width: 0;
        /* 防止子元素超出父容器 */

        .folder_title {
          font-weight: 600;
          font-size: 18px;
          margin-bottom: 8px;
          line-height: normal;
        }

        .description {
          font-size: 13px;
          margin-bottom: 12px;
          color: #4A5154;
          overflow: hidden;
          /* 超出的文本隐藏 */
          text-overflow: ellipsis;
          /* 溢出用省略号显示 */
          white-space: nowrap;
          /* 溢出不换行 */
          max-width: 100%;
          flex-shrink: 1;
        }

        .tags {
          height: 22px;
          overflow: hidden;
          display: flex;
          width: 100%;
          flex-wrap: wrap;
        }
      }

      .bottomInfo {
        display: flex;
        justify-content: space-between;
        align-items: center; // 确保居中对齐
      }

      .folderInfo {
        display: flex;
        align-items: center;
        gap: 16px;
        flex: 1; // 占满剩余空间
        min-width: 0; // 允许 text-overflow 生效
        margin-right: 10px;
      }

      .user {
        color: #768587;
        font-family: "PingFang TC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        overflow: hidden; // 超出的文本隐藏
        text-overflow: ellipsis; // 溢出用省略号显示
        white-space: nowrap; // 溢出不换行
      }

      .count {
        display: flex;
        align-items: center; // 确保图标和文本垂直居中对齐
        color: #768587;
        font-family: "PingFang TC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        flex-shrink: 0; // 不收缩
        gap: 4px;
      }

      .actions {
        display: flex;
        flex-shrink: 0; // 不收缩
      }

      .delete_button {
        margin-left: 10px;
      }

      .delete_button:hover {
        color: #ff4d4f !important;
        border-color: #ff4d4f !important;
      }

      .button {
        :global(.ant-btn-default) {
          color: var(--dot-color) !important;
          border: 1px solid var(--theme-border-1) !important;
          font-weight: 500;
          font-size: 14px;
          box-shadow: none !important;
          background: transparent !important;
        }
      }
    }

    .folderItem:hover {
      background-color: #FFFFFF;
      transform: scale(1.03);
      box-shadow: 0px 12px 16px -4px rgba(21, 30, 58, 0.08),
        0px 4px 6px -2px rgba(21, 30, 58, 0.03);

      .flag {
        transform: scale(1.03);
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2px;
    padding-right: 44px;

    :global(.ant-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;

      :global(.ant-pagination-item) {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        background-color: var(--theme-bg2);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        :global(a) {
          color: var(--theme-color-main);
        }
      }

      :global(.ant-pagination-item-active) {
        background-color: var(--theme-bg6);

        :global(a) {
          color: var(--theme-color-6);
        }
      }

      :global(.ant-pagination-item-ellipsis) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link-icon) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-disabled) {
        :global(.ant-pagination-item-link) {
          color: var(--theme-color-4);
        }
      }
    }
  }

  .dialog {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
}

.modal {
  :global(.ant-form-vertical .ant-form-item-label >label) {
    font-weight: 600;
  }

  :global(.ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after) {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
    visibility: visible;
  }

  :global(.ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
    display: none;
  }

  :global(.ant-btn-default) {
    color: var(--dot-color) !important;
    border: 1px solid var(--theme-border-1) !important;
    font-weight: 500;
    font-size: 14px;
    box-shadow: none !important;
    background: transparent !important;
  }

  :global(.ant-btn-primary) {
    background-color: var(--theme-color-10) !important;
    border: none !important;
    font-weight: 500;
    font-size: 14px;
    color: var(--theme-bg) !important;
    box-shadow: none !important;
  }
}

.folderItemWrapper {
  width: calc((100% - 88px) / 3);
  position: relative;

  .folderItem {
    height: 100%;
  }
}

.folderItemWrapperInsert {
  width: calc((100% - 68px) / 2);
  position: relative;

  .folderItem {
    height: calc(100% - 34px);
  }
}

@media screen and (max-width : 600px) {
  .folder_container {
    margin: 0 !important;
  }
  .folderItemWrapperInsert {
    width: 100%;
    position: relative;
  
    .folderItem {
      height: calc(100% - 34px);
    }
  }
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 控制显示的行数 */
    -webkit-box-orient: vertical; /* 设置子元素的排列方式为垂直 */
    overflow: hidden; /* 隐藏超出的部分 */
    text-overflow: ellipsis; /* 设置省略号 */
    white-space: unset !important;
    min-height: 41px;
  }
  .folderItem:hover {
    transform: unset !important;
    box-sizing: content-box;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1920px) {
  .folderItemWrapper {
    width: calc((100% - 108px) / 4);
  }
}

@media screen and (min-width: 1921px) {
  .folderItemWrapper {
    width: calc((100% - 128px) / 5);
  }
}

.flag {
  border-radius: 0px 12px;
  background: #43C28F;
  position: absolute;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  top: 0;
  right: 0;

}

.select {
  position: relative;

  :global(.ant-select .ant-select-arrow .anticon) {
    display: none;
  }
}

.selectCount {
  position: absolute;
  top: 5px;
  right: 9px;
  color: rgba(0, 0, 0, 0.45);
}

.tags {
  display: flex;
  gap: 4px;
  margin-top: 12px;
}

.upload {
  display: flex;

  :global(.ant-upload-wrapper) {
    width: 64px;
    height: 64px;
  }
}

.upload_card_inside,
.upload_inside {
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
}

.upload_card_inside {
  width: 64px;
  height: 64px;
}

.closeIcon_hide {
  display: none;
}

.bg_img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 12px;
  // background-color: var(--theme-bg2);
}

.add {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--theme-bg2);
  overflow: hidden;
  border-radius: 12px;

  .addIcon {
    width: 20px;
    height: 20px;
    background: var(--theme-color-10);
    border-radius: 50%;
    color: #fff;
    line-height: 24px;
    text-align: center;
  }

  .addText {
    margin-top: 8px;
    color: var(--theme-color-main);
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.closeIcon {
  text-align: center;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0px;
  top: 0px;
  color: rgb(255, 255, 255);
  background: #000;
  opacity: 0.75;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.folderImg {
  background-size: cover;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.formImg {
  margin-top: 24px;
}

.formName {
  :global(.ant-input-data-count) {
    top: 25%;
    left: 90%;
  }

  textArea {
    height: 42px !important;
    min-height: 42px !important;
    line-height: 34px !important;
  }
}

.formDesc {
  :global(.ant-input-data-count) {
    top: 60%;
    left: 88%;
  }
}
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cate {
  flex: 0;
}

.content {
  flex: 1;
  overflow: hidden auto;
  margin-bottom: 16px;
}

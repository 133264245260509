.chat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  // background: var(--theme-bg9);

  .questions {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 110px;
    min-width: 622px;
    width: 738px;

    .question_item {
      width: 305px;
      height: 56px;
      border: 1px solid var(--theme-border-5);
      border-radius: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px 18px;
      background: var(--theme-bg);
      color: var(--theme-color-main);
      margin-right: 12px;
      margin-bottom: 12px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 1;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      line-height: 22px;
      cursor: pointer;

      span {
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  &.chat_home_page {
    max-width: 848px !important;
    min-width: 848px !important;
  }

  &.chat_preview {
    width: 100%;
    padding: 0 44px;

    // .empty {
    //   display: none;
    // }

    .questions {
      width: 100%;
      min-width: unset;
      bottom: 74px;

      .question_item {
        padding: 16px 15px;
        border-radius: 12px;
        height: 54px;
        width: 100%;
        margin-right: 0;
      }
    }

    .chat_inner {
      width: 100%;
      max-height: 100%;
    }

    .chat_history {
      width: 100%;
      height: calc(100vh - 64px);
    }

    .chat_insert {
      .chat_insert_input {
        width: 100%;
      }
    }
  }

  &.chat_advanced {
  }

  &.chat_card {
    width: 100%;
    padding: 0 44px;

    // .empty {
    //   display: none;
    // }

    .questions {
      width: 100%;
      min-width: unset;
      bottom: 74px;

      .question_item {
        padding: 16px 15px;
        border-radius: 12px;
        height: 54px;
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .chat_insert_right {
    position: absolute;
    top: 68px;
    right: 18px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    z-index: 99;
  }

  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .empty_home_page {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      .empty_home_page_title {
        color: var(--theme-color-main);
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        text-align: center;
        margin-top: 10vh;
        .empty_home_page_big_title {
          display: flex;
          justify-content: center;
          align-items: center; /* 新增 */
          .empty_home_page_green {
            background: var(--theme-color-10);
            -webkit-background-clip: text;
            color: transparent;
          }

          .empty_home_page_title_green {
            background-image: var(--theme-color-5);
            -webkit-background-clip: text;
            color: transparent;
          }
        }
      }

      .empty_home_page_desc {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--theme-color-main);
        margin-top: 24px;
        height: 24px;
      }
    }

    &.empty_card {
      justify-content: unset;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        align-self: center;
      }
    }

    .card_empty_img {
      width: 100px;
      height: 100px;
      margin-top: 12vh;
      border-radius: 50%;
    }

    .card_empty_msg {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: var(--theme-color-main);
      margin-top: 16px;
      text-align: center;
    }

    .empty_img {
      width: 183px;
      height: 140px;
    }

    .empty_msg {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-top: 32px;
      color: var(--theme-color-main);
      margin-left: 32px;
    }
  }

  .empty_home_page_question_change {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    span {
      margin-left: 4px;
    }
  }

  .empty_home_page_recommand {
    position: relative;
    top: 160px;
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 290px;

    .questions_title {
      width: 100%;
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        color: #333;
      }

      .questions_refresh {
        width: 95px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: auto; /* 自动将刷新按钮推到右侧 */
        padding: 5px 10px;
        background-color: #fff;
        border-radius: 20px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #eef3f7;
        }

        .img {
          margin-right: 5px;
        }

        span {
          font-size: 14px;
          color: #333;
        }
      }
    }

    .empty_home_page_recommand_item {
      background-color: #fff;
      width: 100%;
      height: 48px;
      border-radius: 12px;
      border: 1px solid var(--theme-border-1);
      margin-top: 16px;
      padding-left: 15px;
      // -webkit-animation-duration: 0.5s;
      // animation-duration: 0.5s;
      display: flex;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px;
      color: #272b30;
      cursor: pointer;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }

      &:nth-child(4) {
        animation-delay: 0.6s;
      }

      .empty_home_page_recommand_info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;
        }
      }

      .empty_home_page_category_icon {
        display: flex;
        margin-top: auto;
        width: 100%;
        align-items: center;
        font-size: 12px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          filter: drop-shadow(var(--theme-color-9) 10000px 0);
        }
      }

      .empty_home_page_recommand_icon {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        margin-right: 12px;
        object-fit: cover;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .chat_inner {
    position: relative;
    width: 640px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    // height: calc(120vh);
  }

  .chat_history {
    height: calc(100vh - 64px);
    padding: 16px 0;
    position: relative;
    word-break: break-all;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    & {
      -ms-overflow-style: none;
    }

    & {
      overflow: -moz-scrollbars-none;
    }

    .chat_history_inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: auto;
      transition: all 0.1s ease-in-out;
      margin-bottom: 64px;
    }

    .chat_history_item {
      width: 100%;
      // display: flex;
      margin-bottom: 16px;
      justify-content: flex-start;
      align-items: center;
      color: var(--theme-color-9);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
      display: flex;
      flex-direction: column;

      &:before {
        content: "";
        position: absolute;
        margin: 0;
        padding: 0;
        font-size: 0;
        line-height: 0;
        border-width: 10px;
        border-color: #ddd;
        border-style: dashed;
        border-left-width: 0;
        border-right-style: solid;
        border-top-color: transparent;
        border-bottom-color: transparent;
        display: inline-block;
        left: -10px;
        top: 50%;
        margin-top: -10px;
      }

      .chat_history_item_msg_loading {
        margin-right: 8px;
      }

      &.chat_history_item_receive {
        .chat_history_item_msg {
          font-size: 16px;
          overflow: hidden;
          width: 100%;
          display: flex;
          justify-content: flex-start;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &.chat_history_item_send {
        flex-direction: row-reverse;

        .chat_history_item_msg {
          margin-right: 12px;
          font-size: 16px;
          width: 100%;
          display: flex;
          justify-content: flex-start;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .chat_history_name_box {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        color: var(--theme-color-main);
        width: 100%;
      }

      .chat_history_item_img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
      }

      .chat_history_item_msg {
        // background-color: var(--theme-bg2);
        font-size: 16px;
        border-radius: 12px;
        display: flex;

        .chat_history_item_wrapper {
          display: flex;
          position: relative;
          justify-content: flex-end;
          width: 100%;
        }

        .chat_history_item_inner {
          margin: 16px 0;
          display: flex;
          align-items: center;
          background: #80868d1a;
          padding: 16px;
          border-radius: 12px;
          font-size: 16px;

        }
      }
    }
  }

  .chat_history_item_loading {
    display: flex;
    width: 250px;
    margin-left: 18px;
    height: 24px;

    :global(.dot-falling) {
      background-color: var(--theme-bg6);
      color: var(--theme-bg6);
      box-shadow: 9999px 0 0 0 var(--theme-bg6);
    }

    :global(.dot-falling::after) {
      background-color: var(--theme-bg6);
      color: var(--theme-bg6);
      box-shadow: 9999px 0 0 0 var(--theme-bg6);
    }
  }

  .chat_to_bottom {
    position: absolute;
    overflow: hidden;
    top: -32px;
    transform: translate(-50%, -50%) rotate(180deg);
    border-radius: 50%;
    border: 1px solid var(--theme-border-1);
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .chat_input_box_wrap {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
  }

  .chat_insert {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4px;

    .chat_modal_choose {
      width: 36px;
      height: 36px;
      margin-right: 12px;
      cursor: pointer;
    }

    .chat_input_wrap_active,
    .chat_input_wrap {
      border-radius: 12px;
      width: 100%;
      overflow: hidden;
      border: 1px solid transparent;
      background: var(--theme-bg);
      padding: 11px 16px 36px;
      // display: flex;
      margin-bottom: 20px;
      align-items: center;
    }

    .chat_input_wrap_active {
      border: 1px solid #007890;
    }

    .chat_insert_input {
      position: relative;
      background: var(--theme-bg);
      width: 100%;
      height: 56px;
      border: none;
      border-radius: 0;
      color: var(--theme-color-main);
      padding: 3px 36px 3px 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      &::-webkit-scrollbar {
        display: none;
        /* Chrome Safari */
      }

      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }

      &:focus-within {
        box-shadow: none;
      }
    }

    .chat_insert_input::placeholder {
      color: var(--theme-color-2);
    }

    .chat_send_btn {
      margin-left: 16px;
    }

    .btnWrap {
      position: relative;
      top: -62px;
      left: 16px;
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    .btnContent {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .ant-switch {
        margin-right: 8px;
      }
    }

    .chat_to_bottom {
      position: absolute;
      bottom: 20px;
    }

    .chat_insert_right {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;

      .chat_insert_send {
        cursor: pointer;
        width: 36px;
        height: 36px;
      }
    }
  }

  .chat_insert_center {
    position: absolute;
    top: calc(10vh + 160px);
    width: 100%;
  }

  .btnWrap {
    width: 100%;
    margin-top: 16px;
    display: flex;
    gap: 12px;
  }

  .btnContent {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.modal_popover_inside {
  width: 104px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal_popover_inside_item {
    display: flex;
    justify-content: center;
    height: 34px;
    align-items: center;
    width: 84px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--theme-color-2);
    border-radius: 12px;
    margin-bottom: 4px;

    &.modal_popover_inside_item_active {
      background: var(--theme-bg);
      color: var(--theme-color-main);
      font-weight: 500;
    }

    &:first-child {
      margin-top: 16px;
    }

    &:last-child {
      margin-bottom: 12px;
    }

    &:hover {
      background: var(--theme-bg);
      color: var(--theme-color-main);
      font-weight: 500;
    }
  }
}

@media screen and (min-height: 1000px) {
  .chat {
    .empty_home_page_recommand {
      top: 200px;
    }
  }
}

@media screen and (max-width: 450px) {
  .chat {
    &.chat_home_page {
      width: 100vw !important;
      min-width: 100vw !important;
      max-width: 100vw !important;
    }

    .empty_home_page_big_title {
      font-size: 1.5rem;
    }

    .chat_insert {
      margin-bottom: 0 !important;
    }
  }
}

.article {
  display: flex;
  flex-wrap: wrap;
  margin-top: 44px;
  padding: 0 24px;
  min-width: 1200px;
  .article_item {
    width: calc(50% - 35px);
    margin-right: 70px;
    margin-bottom: 44px;

    &:nth-child(2n) {
      margin-right: 0px;
    }

    .article_item_context {
      display: flex;
      color: var(--theme-color-main);
      cursor: pointer;

      .left {
        .img {
          width: 148px;
          height: 101px;
          border-radius: 12px;
          object-fit: cover;
        }
      }
      .right {
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        width: calc(70% - 30px);
        .title {
          font-size: 14px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        .desc {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          color: var(--theme-color-2);
          margin-top: 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .count {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin-top: auto;
          color: var(--theme-color-2);
          display: flex;
          align-items: center;

          div {
            display: flex;
            align-items: center;
            height: 22px;
            margin-right: 18px;
          }
          img {
            margin-right: 4px;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 76px;
    margin-top: 2px;
    padding-right: 44px;

    :global(.ant-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;
      :global(.ant-pagination-item) {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        background-color: var(--theme-bg2);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :global(a) {
          color: var(--theme-color-main);
        }
      }

      :global(.ant-pagination-item-active) {
        background-color: var(--theme-bg6);
        :global(a) {
          color: var(--theme-color-6);
        }
      }

      :global(.ant-pagination-item-ellipsis) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link-icon) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-disabled) {
        :global(.ant-pagination-item-link) {
          color: var(--theme-color-4);
        }
      }
    }
  }
}

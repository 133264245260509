.chat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  // background: var(--theme-bg9);

  .questions {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 110px;
    min-width: 622px;
    width: 738px;

    .question_item {
      width: 305px;
      height: 56px;
      border: 1px solid var(--theme-border-5);
      border-radius: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px 18px;
      background: var(--theme-bg);
      color: var(--theme-color-main);
      margin-right: 12px;
      margin-bottom: 12px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 1;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      line-height: 22px;
      cursor: pointer;

      span {
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .chat_workflow {
    padding: 4px 4px;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &.chat_home_page {
    max-width: 848px !important;
    min-width: 848px !important;
  }

  &.chat_preview {
    width: 100%;
    padding: 0 44px;

    // .empty {
    //   display: none;
    // }

    .chat_inner {
      min-width: auto;
    }

    .questions {
      width: 100%;
      min-width: unset;
      bottom: 74px;

      .question_item {
        padding: 16px 15px;
        border-radius: 12px;
        height: 54px;
        width: 100%;
        margin-right: 0;
      }
    }

    .chat_inner {
      width: 100%;
      max-height: 100%;
    }

    .chat_history {
      width: 100%;
      height: calc(100vh - 200px);
    }

    .chat_insert {
      .chat_insert_input {
        width: 100%;
      }
    }
  }

  &.chat_advanced {
  }

  &.chat_card {
    width: 100%;
    padding: 0px;

    .chat_input_wrap {
      border: 1px solid #f2f3f5 !important;
    }

    .chat_input_wrap_active {
      border: 1px solid #007890 !important;
    }

    // .empty {
    //   display: none;
    // }

    .questions {
      width: 100%;
      min-width: unset;
      bottom: 74px;

      .question_item {
        padding: 16px 15px;
        border-radius: 12px;
        height: 54px;
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .chat_insert_right {
    position: absolute;
    right: 18px;
    bottom: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 32px;
    height: 32px;
  }
  .chat_upload {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
    .chat_upload_d {
      padding: 0px 4px;
      line-height: 32px;
    }
  }

  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;

    .empty_home_page {
      height: 100%;
      width: 100%;
      max-height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .empty_home_page_title {
        color: var(--theme-color-main);
        font-size: 58px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        margin-top: 10vh;

        .empty_home_page_title_green {
          background-image: var(--theme-color-5);
          -webkit-background-clip: text;
          color: transparent;
        }

        .empty_home_page_green {
          background-image: var(--theme-color-10);
          -webkit-background-clip: text;
          color: transparent;
        }
      }

      .empty_home_page_desc {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: var(--theme-color-main);
        margin-top: 24px;
      }

      .empty_home_page_question_change {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;

        span {
          margin-left: 4px;
        }
      }

      .empty_home_page_recommand {
        display: flex;
        width: 100%;
        position: relative;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 220px;

        .empty_home_page_recommand_item {
          width: calc(50% - 12px);
          height: 94px;
          padding: 16px;
          border-radius: 12px;
          border: 1px solid var(--theme-border-1);
          margin-top: 16px;
          // -webkit-animation-duration: 0.5s;
          // animation-duration: 0.5s;
          display: flex;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: var(--theme-color-9);
          cursor: pointer;

          &:nth-child(2) {
            animation-delay: 0.2s;
          }

          &:nth-child(3) {
            animation-delay: 0.4s;
          }

          &:nth-child(4) {
            animation-delay: 0.6s;
          }

          .empty_home_page_recommand_info {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              overflow: hidden;
              /*! autoprefixer: off */
              -webkit-box-orient: vertical;
            }
          }

          .empty_home_page_category_icon {
            display: flex;
            margin-top: auto;
            width: 100%;
            align-items: center;
            font-size: 12px;

            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              filter: drop-shadow(var(--theme-color-9) 10000px 0);
            }
          }

          .empty_home_page_recommand_icon {
            width: 60px;
            height: 60px;
            border-radius: 8px;
            margin-right: 12px;
            object-fit: cover;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }
    }

    &.empty_card {
      justify-content: unset;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        align-self: center;
      }
    }

    .card_empty_img {
      width: 100px;
      height: 100px;
      margin-top: 12vh;
      border-radius: 50%;
    }

    .card_empty_msg {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      color: var(--theme-color-main);
      margin-top: 16px;
      text-align: center;
    }

    .empty_img {
      width: 183px;
      height: 140px;
    }

    .empty_msg {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-top: 32px;
      color: var(--theme-color-main);
      margin-left: 32px;
    }
  }

  .chat_inner {
    position: relative;
    min-width: 720px;
    width: 60%;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    // height: calc(120vh);
  }

  .chat_history {
    height: calc(100vh - 226px);
    padding: 16px 0;
    position: relative;
    word-break: break-all;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    & {
      -ms-overflow-style: none;
    }

    & {
      overflow: -moz-scrollbars-none;
    }

    .chat_history_inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: auto;
      transition: all 0.1s ease-in-out;
      margin-bottom: 64px;
    }

    .chat_history_item {
      width: 100%;
      // display: flex;
      margin-bottom: 32px;
      justify-content: flex-start;
      align-items: center;
      color: var(--theme-color-9);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
      display: flex;
      flex-direction: column;

      &:before {
        content: "";
        position: absolute;
        margin: 0;
        padding: 0;
        font-size: 0;
        line-height: 0;
        border-width: 10px;
        border-color: #ddd;
        border-style: dashed;
        border-left-width: 0;
        border-right-style: solid;
        border-top-color: transparent;
        border-bottom-color: transparent;
        display: inline-block;
        left: -10px;
        top: 50%;
        margin-top: -10px;
      }

      .chat_history_item_msg_loading {
        margin-right: 8px;
      }

      &.chat_history_item_receive {
        .chat_history_item_content_wrap {
          border-radius: 8px;
          margin-left: 32px;
          margin-top: 16px;
          background: #fff;
        }
        .chat_history_item_msg {
          font-size: 16px;
          overflow: hidden;
          width: 100%;
          display: flex;
          justify-content: flex-start;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &.chat_history_item_send {
        flex-direction: row-reverse;

        .chat_history_item_msg {
          margin-right: 12px;
          font-size: 16px;
          width: 100%;
          display: flex;
          justify-content: flex-start;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .chat_history_name_box {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        color: var(--theme-color-main);
        width: 100%;
      }

      .chat_history_item_img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
      }

      .chat_history_item_msg {
        // background-color: var(--theme-bg2);
        font-size: 16px;
        display: flex;

        .chat_history_item_wrapper {
          display: flex;
          position: relative;
          justify-content: flex-end;
          width: 100%;
          flex-direction: column;
        }

        .chat_history_user_msg_item {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-left: 32px;
        }

        .chat_history_item_inner {
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          border-radius: 8px !important;
          background: #007c96;

          padding: 16px;
          gap: 10px;
          color: var(--B-aibg-color-bg-1, #f7f8fa);

          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .chat_history_item_loading {
    display: flex;
    width: 250px;
    margin-left: 18px;
    height: 24px;

    :global(.dot-falling) {
      background-color: var(--theme-bg6);
      color: var(--theme-bg6);
      box-shadow: 9999px 0 0 0 var(--theme-bg6);
    }

    :global(.dot-falling::after) {
      background-color: var(--theme-bg6);
      color: var(--theme-bg6);
      box-shadow: 9999px 0 0 0 var(--theme-bg6);
    }
  }

  .chat_to_bottom {
    position: absolute;
    overflow: hidden;
    top: -32px;
    transform: translate(-50%, -50%) rotate(180deg);
    border-radius: 50%;
    border: 1px solid var(--theme-border-1);
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .chat_workflow_send {
    position: absolute;
    top: -38px;
    padding: 6px 12px 6px 12px;
    border-radius: 4px;
    border: 1px solid var(--B-border-color-border-1, #f2f3f5);
    background: var(--B-bg-color-bg-1, #ffffff);
    font-size: 14px;
    color: var(---800, #171e38);
    .chat_workflow_send_img {
      width: 16px;
      height: 16px;
      vertical-align: bottom;
      margin-right: 2px;
    }
  }

  .chat_input_box_wrap {
    z-index: 1;
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
  }

  .chat_input_box_conversation_img {
    position: absolute;
    left: -40px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .chat_insert {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4px;

    .chat_modal_choose {
      width: 36px;
      height: 36px;
      margin-right: 12px;
      cursor: pointer;
    }

    .chat_input_wrap_active,
    .chat_input_wrap {
      border-radius: 12px;
      width: 100%;
      overflow: hidden;
      border: 1px solid transparent;
      background: var(--theme-bg);
      padding: 11px 16px;
    }

    .chat_input_wrap_active {
      border: 1px solid #007890;
    }

    .chat_insert_input {
      position: relative;
      background: var(--theme-bg);
      // width: 738px;
      height: 56px;
      border: none;
      border-radius: 0;
      color: var(--theme-color-main);
      padding: 3px 36px 3px 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      &::-webkit-scrollbar {
        display: none;
        /* Chrome Safari */
      }

      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }

      &:focus-within {
        box-shadow: none;
      }
    }

    .chat_insert_input::placeholder {
      color: var(--theme-color-2);
    }

    .chat_send_btn {
      margin-left: 16px;
    }
  }

  .chat_insert_center {
    top: -50%;
  }

  .btnWrap {
    width: 100%;
    margin-top: 16px;
    display: flex;
    gap: 36px;
  }

  .btnContent {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .assistant_box {
    .assistant_box_top {
      margin-top: 24px;
      display: flex;
      img {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        vertical-align: middle;
      }
      div {
        font-weight: 600;
        font-size: 16px;
        color: var(--C-, #272b30);
        margin-left: 8px;
        vertical-align: middle;
      }
    }
    .assistant_box_middle {
      margin-top: 16px;
      font-size: 16px;
      color: var(--C-, #272b30);
      margin-bottom: 24px;
    }
    .assistant_box_questions {
      min-width: 622px;
      width: 380px;
      cursor: pointer;
      .assistant_box_question_item {
        min-width: 380px;
        padding: 16px 15px 16px 15px;
        border-radius: 12px;
        border: 1px solid var(--C-, #eeeeee);
        background: var(--C-, #ffffff);
        font-size: 16px;
        font-weight: 500;
        color: var(--C-, #272b30);
        margin-bottom: 8px;
      }
    }
  }
}

.modal_popover_inside {
  width: 104px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal_popover_inside_item {
    display: flex;
    justify-content: center;
    height: 34px;
    align-items: center;
    width: 84px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: var(--theme-color-2);
    border-radius: 12px;
    margin-bottom: 4px;

    &.modal_popover_inside_item_active {
      background: var(--theme-bg);
      color: var(--theme-color-main);
      font-weight: 500;
    }

    &:first-child {
      margin-top: 16px;
    }

    &:last-child {
      margin-bottom: 12px;
    }

    &:hover {
      background: var(--theme-bg);
      color: var(--theme-color-main);
      font-weight: 500;
    }
  }
}

.chat_super_assistant_wrap {
  width: 100%;
  height: 100%;
  .chat_input_box_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    min-width: 720px;
    left: 50%;
    transform: translate(-50%);
    align-items: center;
    position: relative;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
    border: var(--Size-Line-Width-lineWidth, 1px) solid
      var(--B-border-color-border-1, #f2f3f5);
    box-shadow: 0px 20px 24px -4px rgba(28, 33, 42, 0.08),
      0px 8px 8px -4px rgba(28, 33, 42, 0.03);
  }

  .chat_input_active,
  .chat_input_wrap {
    width: 100%;
    overflow: hidden;
    border: 1px solid transparent;
    background: var(--theme-bg);
    padding: 11px 16px;
  }

  .chat_input_active {
    background: var(--theme-bg);
  }

  .agentBtn,
  .agentBtnActive {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--theme-color-9);
    padding: 0;
    box-shadow: none;
    border: 1px solid var(--B-border-color-border-1, #f2f3f5);
    &:hover {
      color: black !important;
      background: rgba(0, 0, 0, 0.1) !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }

  .agentBtnNumbers {
    font-size: 14px;
    color: var(--theme-color-10);
  }

  .agentBtnActive {
    border-radius: 45px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(90deg, #0fc 0%, #a917ff 100%);
  }

  .agentIcon {
    width: 20px;
    height: 20px;
  }
  .agentBtnContent {
    gap: 8px;
    height: 34px;
    justify-content: center;
    // background: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    width: calc(100% - 2px);
    border-radius: 44px;
    padding: 8px 12px;
  }
}

.filesList {
  width: 100%;
  max-width: 100%;
  position: absolute;
  top: calc(100% - 12px);
  overflow: hidden;
}

@media screen and (max-width: 450px) {
  .filesList {
    right: 0;
    width: calc(100% + 15px);
  }
  .mobileChatInput {
    display: flex;
    flex-direction: column;
    // box-shadow: unset !important;
    // border: unset !important;
    // background: #e0e6e8;
    .filesList {
      top: unset;
      overflow: auto;
    }
    .chat_insert_right {
      bottom: unset;
    }
  }

  .chat_insert {
    width: calc(100% - 32px);
    align-self: center;
  }
  .chat_input_active {
    border-radius: 8px;
    box-shadow: 0px 20px 24px -4px rgba(28, 33, 42, 0.08),
      0px 8px 8px -4px rgba(28, 33, 42, 0.03);
    border: 1px solid #f2f3f5;
    position: relative;
    z-index: 2;
  }
  .chat {
    &.chat_home_page {
      min-width: 100vw !important;
      max-width: 100vw !important;
    }

    .chat_inner {
      min-width: 100vw !important;
    }

    .chat_history {
      height: calc(100vh - 135px);
      padding: 16px 20px;
    }

    .chat_input_box_wrap {
      max-width: calc(100vw - 32px);
      min-width: auto;
    }
  }
}

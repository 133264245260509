.page {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  & {
    -ms-overflow-style: none;
  }

  & {
    overflow: -moz-scrollbars-none;
  }

  .title {
    font-family: Hind;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    margin-top: 20px;
    color: var(--theme-bg6);
    top: 216px !important;
    margin-left: 24px;
    margin-right: 24px;
    margin: 20px 24px 0 24px;
    display: flex;
    align-items: center;

    .back {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .skeleton_container {
    width: 260px;
    background-color: var(--theme-bg2);
    margin: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .skeleton_image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;

      img {
        width: 80px;
        height: 80px;
      }
    }

    .skeleton_info {
      height: 100px;
      margin-top: 12px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .skeleton_title {
        position: absolute;
        top: 0;
        right: 10px;
      }

      .skeleton_content {
        bottom: 10px;
        left: -145px;
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        position: absolute;
        width: 142px;
      }
    }
  }

  .card_container {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: hidden;
    background-color: var(--theme-bg1);
    padding: 0 24px;
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 2px;
    padding-right: 44px;

    :global(.ant-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;

      :global(.ant-pagination-item) {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        background-color: var(--theme-bg2);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        :global(a) {
          color: var(--theme-color-main);
        }
      }

      :global(.ant-pagination-item-active) {
        background-color: var(--theme-bg6);

        :global(a) {
          color: var(--theme-color-6);
        }
      }

      :global(.ant-pagination-item-ellipsis) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link-icon) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-disabled) {
        :global(.ant-pagination-item-link) {
          color: var(--theme-color-4);
        }
      }
    }
  }

  .file_upload {
    padding-left: 24px;
    padding-top: 20px;
  }

  @media screen and (max-width: 1440px) {
    .page {
      .page_list_item {
        width: calc(33% - 44px);
        margin-right: 44px;
        margin-bottom: 28px;

        &:nth-child(4n) {
          margin-right: 44px !important;
        }

        &:nth-child(3n) {
          margin-right: 44px !important;
        }
      }
    }
  }
}

.modal {
  :global(.ant-modal-content) {
    padding: 0;
    border-radius: var(--Radius-m, 12px);
  }

  :global(.ant-modal-header) {
    padding: 12px 28px 12px 28px;
    background: var(--B-color-bg-2, #f7f8fa);
  }

  :global(.ant-modal-body) {
    padding: 12px 28px 12px 28px;
    justify-content: center;
  }

  :global(.ant-modal-footer) {
    padding: 24px 28px 12px 28px;
    margin-top: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.operator {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnWrap {
  display: flex;
  align-items: center;
  gap: 12px;
}

.form {
  margin: 16px 0 24px;
}

.label {
  color: var(--B-color-text-5, #1b212b);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 4px;
}

.input {
  height: 54px;
  border: var(--Size-Line-Width-lineWidth, 1px) solid
    var(--C-color-border-1, #eee);
  background: var(--C-color-bg-2, #f7f7f7);
}

$theme-dot-color: var(--dot-color);
@use "three-dots" with (
  $dot-width: 7px,
  $dot-height: 7px,
  $dot-color: #80868d
);

html {
  transition: color 0.6s ease-in-out, background-color 0.6s ease-in-out;

  :where(.css-dev-only-do-not-override-gzal6t)[class^="ant-layout"],
  :where(.css-dev-only-do-not-override-gzal6t)[class*=" ant-layout"] {
    font-family: "Hind" !important;
  }

  // 一些通用的默认颜色
  --icon-active-color: #29cc6a;

  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  & {
    -ms-overflow-style: none;
  }

  & {
    overflow: -moz-scrollbars-none;
  }
}

html[lang="en"] {
}

// 默认主题(白天模式)
html[theme="light"] {
  --theme-bg: #fff;
  --header-bg: #fff;
  --theme-bg2: #f7f7f7;
  --theme-bg3: #f5f5f5;
  --theme-bg4: #f7f7f7;
  --theme-bg5: rgba(0, 0, 0, 0.5);
  --theme-bg6: #272b30;
  --theme-bg7: rgba(16, 16, 15, 0.5);
  --theme-bg8: #f7f7f7;
  --theme-bg9: #f7f7f7;
  --theme-bg10: #e5e7e9;
  --theme-bg11: #e5e7e9;
  --theme-bg12: radial-gradient(100% 100% at 50% 0%, #e3fde2 0%, #fff 75%);
  --theme-bg13: #eeeeee;
  --theme-bg14: #f4f9fc;
  --theme-bg-skeleton: #f7f7f7;
  --theme-bg-skeleton-2: #eeeeee;
  --theme-bg-mask: rgba(0, 0, 0, 0.25);
  --theme-bg-mask-2: rgba(0, 0, 0, 0.5);
  --theme-logout-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  --theme-border-1: #eee;
  --theme-border-2: linear-gradient(90deg, #007890 0%, #29eac4 100%);
  --theme-border-3: #fff;
  --theme-border-4: #eee;
  --theme-border-5: #eee;
  --theme-border-6: #f7f7f7;
  --theme-color-main: #272b30;
  --theme-color-2: #80868d;
  --theme-color-3: #000;
  --theme-color-4: #54585b;
  --theme-color-5: linear-gradient(90deg, #007890 0%, #29eac4 100%);
  --theme-color-6: #fff;
  --theme-color-7: #fff;
  --theme-color-8: #80868d;
  --theme-color-9: #252b32;
  --theme-color-10: #007890;
  --theme-card-source-bg: #dcf1f5;
  --dot-color: #272b30;
  --icon-default-color: #272b30;
  --tooltip-bg-color: #fff;
  --tooltip-text-color: #252b32;
}
// 黑夜模式
html[theme="dark"] {
  --theme-bg: #10100f;
  --header-bg: rgba(16, 16, 15);
  --theme-bg2: #1d1d1b;
  --theme-bg3: #7a7a7a;
  --theme-bg4: #414140;
  --theme-bg5: rgba(0, 0, 0, 0.5);
  --theme-bg6: #fff;
  --theme-bg7: rgba(16, 16, 15, 0.5);
  --theme-bg8: #2f2f2d;
  --theme-bg9: #1d1d1b;
  --theme-bg10: #414140;
  --theme-bg11: #1d1d1b;
  --theme-bg12: radial-gradient(100% 100% at 50% 0%, #0d2b0d 0%, #10100f 75%);
  --theme-bg13: #2f2f2d;
  --theme-bg14: #f4f9fc;
  --theme-bg-skeleton: #393936;
  --theme-bg-skeleton-2: #2f2f2d;
  --theme-bg-mask: rgba(0, 0, 0, 0.5);
  --theme-bg-mask-2: rgba(0, 0, 0, 0.5);
  --theme-logout-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.05),
    0px 4px 6px -4px rgba(255, 255, 255, 0.05);
  --theme-border-1: #2f2f2d;
  --theme-border-2: linear-gradient(90deg, #007890 0%, #29eac4 100%);
  --theme-border-3: #000;
  --theme-border-4: #414140;
  --theme-border-5: #2f2f2d;
  --theme-border-6: #1d1d1b;
  --theme-color-main: #fff;
  --theme-color-2: #a7a7a7;
  --theme-color-3: #000;
  --theme-color-4: #bfbfbf;
  --theme-color-5: linear-gradient(90deg, #007890 0%, #29eac4 100%);
  --theme-color-6: #1f1f1f;
  --theme-color-7: #fff;
  --theme-color-8: #9aa3a3;
  --theme-color-9: #a7a7a7;
  --theme-color-10: #007890;
  --theme-card-source-bg: #263a3d;
  --dot-color: #e8e8e6;
  --icon-default-color: #f7f7f7;
  --tooltip-bg-color: #1d1d1b;
  --tooltip-text-color: #e8e8e6;
}
// 其他主题

.header {
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 11;
  min-width: 1400px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--theme-border-1);
  background-color: var(--theme-bg);

  .headerRight,
  .headerLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-right: 24px;
  }

  .back_box {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 21px;
    cursor: pointer;
    &:hover {
      background-color: var(--theme-bg2);
      border-radius: 4px;
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .avatar_car_box,
  .avatar_box {
    width: 48px;
    height: 48px;
    .avatar_url {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
    }
  }
  .agentName {
    color: var(--dot-color);
    font-size: 16px;
    font-weight: 600;
  }

  .editTip {
    color: var(--theme-color-2);
    font-size: 12px;
  }

  .publishBtn,
  .saveBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 1px solid var(--theme-border-1);
    color: var(--dot-color);
    font-size: 14px;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 600;
  }

  .saveIcon {
    width: 20px;
    height: 20px;
  }

  .saveBtn {
    &:hover {
      color: #00d44b;
      border-color: #00d44b;
    }
  }

  .publishBtn {
    &:disabled {
      background-color: var(--theme-bg8);
    }
    background: var(--Linear, linear-gradient(90deg, #b7ff00 0%, #00fff1 100%));
    color: #252b32;
  }

  .header_title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: var(--theme-color-main);
  }
}

.page {
  z-index: 11;
  height: calc(100vh - 80px);
  display: flex;
  min-width: 1400px;
  width: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 80px;
  background-color: var(--theme-bg);

  .right {
    flex: 1;
    display: flex;

    .create_context {
      width: 62%;
      overflow-y: scroll;
      min-width: 750px;
      max-width: 900px;
    }

    .create_preview {
      min-width: 38%;
      flex: 1;
      height: 100%;
      background-color: var(--theme-bg2);
    }
  }

  .avatar_box {
    width: 247.654px;
    height: 169px;
    .avatar_url {
      width: 247.654px;
      height: 169px;
    }
  }
}

.chat_inner {
    position: relative;
    min-width: 720px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh);
    background: radial-gradient(100% 100% at 50% 0%, #D6FEFE 0%, #F1F8F9 75%);
}

.chat_history {
    height: 100vh;
    padding: 16px 0;
    position: relative;
    word-break: break-all;
    width: 50%;
    background: #fff;
    margin-top: 80px;
    padding: 48px;
    border-radius: 24px;
    height: fit-content;

    &::-webkit-scrollbar {
        width: 0 !important;
    }

    & {
        -ms-overflow-style: none;
    }

    & {
        overflow: -moz-scrollbars-none;
    }

    .chat_history_inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: auto;
        transition: all 0.1s ease-in-out;
        margin-bottom: 64px;
    }
}

.chat_history_item {
    width: 100%;
    // display: flex;
    margin-bottom: 32px;
    justify-content: flex-start;
    align-items: center;
    color: var(--theme-color-9);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
    display: flex;
    flex-direction: column;

    .chat_history_item_msg_loading {
        margin-right: 8px;
    }

    &.chat_history_item_receive {
        .chat_history_item_content_wrap {
            border-radius: 8px;
            margin-left: 32px;
            margin-top: 16px;
        }

        .chat_history_item_msg {
            font-size: 16px;
            overflow: hidden;
            width: 100%;
            display: flex;
            justify-content: flex-start;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &.chat_history_item_send {
        flex-direction: row-reverse;

        .chat_history_item_msg {
            margin-right: 12px;
            font-size: 16px;
            width: 100%;
            display: flex;
            justify-content: flex-start;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .chat_history_name_box {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        color: var(--theme-color-main);
        width: 100%;
    }

    .chat_history_item_img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
        object-fit: cover;
    }

    .chat_history_item_msg {
        font-size: 16px;
        display: flex;

        .chat_history_item_wrapper {
            display: flex;
            position: relative;
            justify-content: flex-end;
            width: 100%;
            flex-direction: column;
        }

        .chat_history_user_msg_item {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-left: 32px;
        }

        .chat_history_item_inner {
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            border-radius: 8px !important;
            background: #1D21291A;

            padding: 16px;
            gap: 10px;
            color: #1D2129;

            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.headerTitle {
    color: #1B212B;
    font-family: "PingFang SC";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}

.headerDesc {
    color: #81909E;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.spin {
    margin-top: 45vh;
}

.askBtn {
    border-radius: 8px;
    background: #007C96 !important;
    width: 234px;
    height: 54px;
    color: #FFF !important;
    text-align: center;
    font-family: "PingFang SC";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    box-shadow: none !important;
    border: none !important;
    padding: 12px 0;
    transition: bottom 0.3s ease-in-out;
    bottom: 0;
    position: relative;
}

.askWrap {
    margin: 40px 0 60px 0;
    min-height: 54px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.atBottom {
    position: relative;
    bottom: 0;
}

.atTop {
    position: fixed;
    bottom: 40px;
}

@media screen and (max-width: 450px) {
    .chat_history_item_content_wrap {
        margin-left: 0 !important;
        margin-top: 0 !important;
    }

    .chat_inner {
        min-width: 100%;
        background: #fff;
    }

    .chat_history {
        border-radius: 0;
        width: 100%;
        margin-top: 10px;
        padding: 24px;
    }
    .headerTitle {
        font-size: 24px;
    }
    .chat_history_content {
        margin-right: 0 !important;
    }
}
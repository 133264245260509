body {
  margin: 0;
  font-family: "Hint", "HarmonyOS Sans" !important;
  /* font-family: "HarmonyOS Sans"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--theme-bg);
  font-size: 12px;
  vertical-align: baseline;
  color: var(--theme-color-2);
}
.enterpriseZonePage {
  min-height: 100%;
  background-color: #fff;
  .empty_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;
    &_title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      color: var(--theme-bg6);
    }
    &_content {
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: center;
      color: var(--theme-bg6);
      margin-top: 12px;
      span{
        cursor: pointer;
        color: var(--B-, #007890);
      }
    }
  }

  .category {
    display: flex;
    margin-left: 24px;

    .sider_menu_item {
      margin-top: 24px;
      margin-bottom: 24px;
      height: 38px;
      padding: 8px 16px 8px 16px;
      gap: 10px;
      border-radius: 22px;
      display: flex;
      align-items: center;
      background: var(--theme-bg);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-right: 16px;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      .sider_menu_item_icon {
        width: 30px;
        height: 30px;
        margin-right: 12px;
      }
      &.sider_menu_item_active {
        transition: all 0.25s ease-in-out;
        background: var(--theme-bg2);
        color: var(--theme-color-main);
        font-weight: 600;
        .label {
          color: var(--theme-color-main);
          font-weight: 600;
        }
      }
      &:hover {
        background: var(--theme-bg2);
        color: var(--theme-color-main);
        font-weight: 600;
        .label {
          color: var(--theme-color-main);
          font-weight: 600;
        }
      }
    }
  }

  .page_header {
    position: sticky;
    top: 64px;
    background-color: var(--theme-bg);
    z-index: 4;
  }

  .category_two {
    margin-left: 80px;
    display: flex;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 24px;
    height: 32px;

    .category_two_item {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
      border-radius: 12px;
      margin-right: 20px;
      color: var(--theme-color-2);

      &:hover {
        background-color: var(--theme-bg2);
        color: var(--theme-color-main);
        font-weight: 500;
      }

      &.category_two_item_active {
        background-color: var(--theme-bg2);
        color: var(--theme-color-main);
        font-weight: 500;
      }
    }
  }

  .page_recommand {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: 24px;
    margin-right: 52px;

    .page_recommand_item {
      margin-right: 16px;
      margin-bottom: 16px;
      max-width: calc(33% - 32px);
      min-width: 282px;
    }
    .page_recommand_item_card {
      margin-right: 32px;
      margin-bottom: 24px;
      width: calc(20% - 32px);
      max-width: 240px;
    }
  }

  .page_list {
  }

  .skeleton {
    .skeleton_info {
      height: 38px;
      margin-top: 12px;
      display: flex;
      align-items: center;

      .skeleton_info_name {
        margin-left: 8px;
      }
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 76px;
    margin-top: 2px;
    padding-right: 44px;

    :global(.ant-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;
      :global(.ant-pagination-item) {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: none;
        background-color: var(--theme-bg2);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        :global(a) {
          color: var(--theme-color-main);
        }
      }

      :global(.ant-pagination-item-active) {
        background-color: var(--theme-bg6);
        :global(a) {
          color: var(--theme-color-6);
        }
      }

      :global(.ant-pagination-item-ellipsis) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link-icon) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-item-link) {
        color: var(--theme-color-main);
      }

      :global(.ant-pagination-disabled) {
        :global(.ant-pagination-item-link) {
          color: var(--theme-color-4);
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .page {
    .page_recommand_item_card {
      margin-right: 32px;
      margin-bottom: 24px;
      width: calc(25% - 32px);
      max-width: 240px;

      &:nth-child(4n) {
        margin-right: 28px !important;
      }

      &:nth-child(3n) {
        margin-right: 28px !important;
      }
    }
    .page_recommand_item {
      max-width: calc(33% - 32px);
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    .page_recommand_item_card {
      width: calc(33% - 32px);
      margin-right: 32px;
      margin-bottom: 24px;
      max-width: 240px;

      &:nth-child(4n) {
        margin-right: 28px !important;
      }

      &:nth-child(3n) {
        margin-right: 28px !important;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .page {
    .page_recommand_item_card {
      margin-right: 32px;
      margin-bottom: 24px;
      width: calc(25% - 32px);
      max-width: 240px;

      &:nth-child(4n) {
        margin-right: 28px !important;
      }

      &:nth-child(3n) {
        margin-right: 28px !important;
      }
    }
    .page_recommand_item {
      max-width: calc(33% - 12px) !important;
    }
  }
}

@media screen and (min-width: 1700px) and (max-width: 1800px) {
  .page {
      .page_recommand_item {
        width: calc(25% - 32px);
      }
  }
}

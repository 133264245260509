.modal {
  :global(.ant-modal-content) {
    background: var(--theme-bg);
    color: var(--theme-color-2);
  }

  :global(.ant-modal-header) {
    background: var(--theme-bg);
    color: var(--theme-color-main);
  }

  :global(.ant-modal-close-x) {
    color: var(--theme-color-main);
  }
  :global(.ant-modal-title) {
    color: var(--theme-color-main);
  }

  :global(.ant-btn-default) {
    color: var(--dot-color) !important;
    border: 1px solid var(--theme-border-1) !important;
    font-weight: 500;
    font-size: 14px;
    box-shadow: none !important;
    background: transparent !important;
  }

  :global(.ant-btn-primary) {
    background: rgb(0, 124, 150) !important;
    font-weight: 500;
    font-size: 14px;
    color: rgb(255, 255, 255) !important;
    box-shadow: none !important;
  }

  :global(.ant-btn-dangerous) {
    background: #ff4d4f !important;
    border: none !important;
    font-weight: 500;
    font-size: 14px;
    color: #fff !important;
    box-shadow: none !important;
  }
}

.title {
  display: flex;
  gap: 8px;
}

.content {
  color: var(--theme-color-main);
}

.page {
  height: 100%;
  margin: 0 24px;

  .page_header {
    .title {
      display: flex;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 16px;
      height: 40px;
      img {
        width: 40px;
        height: 100%;
      }
      div {
        margin-left: 12px;
        color: var(--theme-bg6);
        font-size: 16px;
        font-weight: 600;
      }
    }
    .logo {
      border-radius: 50%;
    }
    .number_box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      .number {
        display: flex;
        margin-top: 7px;
        div {
          margin-right: 24px;
          font-family: PingFang SC;
          font-size: 13px;
          font-weight: 400;
          line-height: 18.2px;
          text-align: left;
          color: #4c5154;
        }
        span {
          font-family: PingFang SC;
          font-size: 18px;
          font-weight: 500;
          text-align: left;
          color: var(--theme-bg6);
          margin-left: 8px;
        }
      }
      .publish_btn {
        background: var(--theme-color-10);
        color: var(--theme-bg);
        width: 88px;
        font-family: PingFang TC;
        font-size: 14px;
      }
    }
  }
  .table_box {
    .activate {
      display: inline-block;
      width: 44px;
      height: 20px;
      line-height: 20px;
      border-radius: 30px;
      background: #d1fae5;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #065f46;
    }
    .delete {
      display: inline-block;
      width: 58px;
      height: 20px;
      line-height: 20px;
      border-radius: 30px;
      background-color: var(--theme-bg2);
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: var(--theme-color-2);
    }
    .none_activate {
      display: inline-block;
      width: 58px;
      height: 20px;
      line-height: 20px;
      border-radius: 30px;
      background-color: #fef6ed;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #eda145;
    }
    .time {
      color: var(--B-placeholder, #7b8587);
      font-size: 14px;
    }
    .deleteText {
      cursor: pointer;
      font-size: 14px;
      color: var(--C-, #ff2e4d);
    }
    .pagination {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-bottom: 76px;
      margin-top: 20px;
      padding-right: 44px;
      :global(.ant-pagination) {
        display: flex;
        align-items: center;
        justify-content: center;
        :global(.ant-pagination-item) {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          border: none;
          background-color: var(--theme-bg2);
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          :global(a) {
            color: var(--theme-color-main);
          }
        }
        :global(.ant-pagination-item-active) {
          background-color: var(--theme-bg6);
          :global(a) {
            color: var(--theme-color-6);
          }
        }
        :global(.ant-pagination-item-ellipsis) {
          color: var(--theme-color-main);
        }
        :global(.ant-pagination-item-link-icon) {
          color: var(--theme-color-main);
        }
        :global(.ant-pagination-item-link) {
          color: var(--theme-color-main);
        }
        :global(.ant-pagination-disabled) {
          :global(.ant-pagination-item-link) {
            color: var(--theme-color-4);
          }
        }
      }
    }
  }
  .table_box :global(.ant-table-thead>tr>th) {
    color: #7b8587;
    font-weight: 400;
    padding: 0 16px;
  }
  .table_box :global(.ant-table-thead >tr) {
    height: 38px;
  }
  .table_box :global( .ant-table-wrapper table) {
    border: 1px solid #f0f0f0 ;
    border-bottom: none;
  }
}
$item-height: 44px;
$gap: 0px;

.modal {
  overflow: hidden;
  padding: 4px !important;
  :global(.ant-modal-content) {
    padding: 80px 32px;
    border-radius: 20px;
    background: var(--theme-bg);
    color: var(--theme-color-2);
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.25);
  }

  :global(.ant-modal-header) {
    background: var(--theme-bg);
    color: var(--theme-color-main);
  }

  :global(.ant-modal-close-x) {
    color: var(--theme-color-main);
  }
  :global(.ant-modal-title) {
    color: var(--theme-color-main);
  }
}

.logo {
  margin-bottom: 48px;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-color-main);
  flex-direction: column;
  gap: $gap;
  :global(.ant-form-item) {
    margin-bottom: 0;
  }
  :global(.ant-form) {
    width: 100%;
  }
}

.itemWrap {
  cursor: pointer;
  box-sizing: border-box;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--theme-border-1);
  margin-bottom: 12px;
}

.itemContent {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  gap: 12px;
  cursor: pointer;
  color: var(--theme-color-9);

  .itemIcon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.bindTips {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 16px;
  color: var(--theme-color-2);
}

.modal {

  :global(.ant-modal-content) {
    background: var(--theme-bg2);
    color: var(--theme-color-2);
  }

  :global(.ant-modal-header) {
    background: var(--theme-bg2);
    color: var(--theme-color-main);
  }

  :global(.ant-modal-close-x) {
    color: var(--theme-color-main);
  }
  :global(.ant-modal-title) {
    color: var(--theme-color-main);
  }
}

.content {
  h1 {
    font-weight: 600;
    font-size: 1.2em;
    color: var(--theme-color-main);
    padding: 8px 0 4px ;
    
  }
  span, p {
    display: inline-block;
    line-height: 24px;
    color: var(--theme-color-main);
  }

  p::before {
    margin-right: 5px; 
    content: "•"; 
    color: blue; 
    font-size: 20px;
  }
}

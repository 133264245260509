$item-height: 54px;
$gap: 20px;

.modal {
  overflow: hidden;
  padding: 4px !important;
  :global(.ant-modal-content) {
    border-radius: 8px;
    background: var(--theme-bg);
    color: var(--theme-color-2);
    box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.25);
    padding: 0;
    overflow: hidden;
  }

  :global(.ant-modal-header) {
    background: var(--theme-bg);
    color: var(--theme-color-main);
  }

  :global(.ant-modal-close-x) {
    color: var(--theme-color-main);
  }
  :global(.ant-modal-title) {
    color: var(--theme-color-main);
  }
}

.title {
  color: var(--B-text-color-text-5, #1b212b);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
}

.qrCode,
.content {
  width: 100%;
  display: flex;
  color: var(--theme-color-main);
  flex-direction: column;
  :global(.ant-form-item) {
    margin-bottom: 0;
  }
  :global(.ant-form) {
    width: 100%;
  }
}

.qrCode {
  margin: 82px 0;
}

.infoWrap {
  display: flex;
  height: 320px;
}

.form {
  padding: 24px 40px 38px;

  :global(.ant-btn-primary) {
    &:hover {
      border: none;
      background: #298ea2 !important;
      color: #272b30 !important;
    }
    &:active {
      border: none;
      background: #298ea2 !important;
      color: #272b30 !important;
    }
  }

  .continue[disabled] {
    opacity: 0.5; /* 设置禁用状态下的透明度 */
    cursor: not-allowed; /* 设置禁用状态下的鼠标样式 */
    background: var(--theme-color-10);
    color: var(--theme-bg);
  }
}

.form_item {
  width: 100%;
  :global(.ant-form-item-row) {
    width: 100% !important;
  }
  :global(.ant-form-item-control-input-content) {
    display: flex !important;
    justify-content: flex-end !important;
  }
}

.input {
  padding: 8px 16px;
  height: $item-height;
  width: 100%;
  background-color: var(--theme-bg4);
  color: var(--B-text-color-text-5, #1b212b);
  border: none;
  border-radius: 4px;
  font-size: 16px;
  &::placeholder {
    color: var(--B-text-color-text-3, #81909e);
    border: none;
  }

  &:global(.ant-input-outlined:hover) {
    background-color: var(--theme-bg4) !important;
  }
  &:global(.ant-input-outlined:focus) {
    background-color: var(--theme-bg4) !important;
  }
  &:global(.ant-input-status-error) {
    color: #f53f3f;
    &::placeholder {
      color: #f53f3f;
    }
  }
}

.gettingCodeWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  color: var(--theme-color-8);
  font-size: 16px;
  gap: 4px;
}

.getCodeBtn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 16px;
  top: 12px;
  height: 22px !important;
  line-height: 22px;
  color: var(--B-text-color-text-5, #1b212b);
  font-size: 16px;
  border-left: 1px solid var(--theme-border-1);
  padding: 0;
  padding-left: 12px;
  background-color: transparent !important;
  border-radius: 0;
  margin: 0;
}

.continue {
  width: 100%;
  align-self: stretch;
  height: $item-height;
  background: var(--theme-color-10);
  border-radius: 4px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  color: var(--theme-bg);
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;
  border: none;
  margin-top: $gap;
}

.goLogin {
  color: var(--theme-bg);
}

.splitWrap {
  width: 100%;
  height: 24px;
  justify-content: flex-start;
  align-items: center;
  gap: $gap;
  display: inline-flex;
}

.splitLine {
  flex: 1 1 0;
  height: 0;
  border: 1px var(--theme-border-1) solid;
}

.splitText {
  color: var(--theme-color-2);
  font-size: 15px;
}

.extraItem {
  width: 100%;
  height: $item-height;
  background: var(--theme-bg2);
  border-radius: 24px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  border: none;
  display: inline-flex;
}

.extraText {
  color: var(--theme-color-main);
  font-size: 15px;
  font-weight: 500;
  word-wrap: break-word;
}

.acceptTextWrap {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  color: var(--B-text-color-text-3, #81909e);
  font-size: 12px;
  gap: 4px;
  border: none;
  box-shadow: none;

  & .acceptText {
    color: var(--B-text-color-text-5, #1b212b);
    font-weight: 500;
    cursor: pointer;
  }
}

.extraWrap {
  width: 100%;
  display: flex;
  gap: $gap;
  flex-direction: column;

  :global(.ant-btn-primary) {
    box-shadow: none;

    &:hover {
      border: none;
      background: var(--theme-bg2) !important;
    }
    &:active {
      border: none;
      background: var(--theme-bg2) !important;
    }
  }
}

.wechatTitle {
  display: flex;
  justify-content: space-between;
  margin-top: -4px;
}

.wechatTitleTextWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  align-items: center;
  gap: 6px;
}

.wechatSubText {
  color: var(--theme-color-8);
  font-size: 14px;
  font-weight: 400;
}

.icon {
  width: 16px;
  height: 16px;
  animation: rotate 2s linear infinite; /* 使用 rotate 动画，2秒一次，线性过渡，无限循环 */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wechatIframeWrap {
  width: 180px;
  display: flex;
  
}
.wechatIframe {
  border: none;
  transform: scale(0.5) translateX(-50%) translateY(-25%);

  :global(.title) {
    font-size: 40px;
  }
}
